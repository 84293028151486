import React, { Component } from "react";
import Statements from "./Statements/Statements";
import withAuth from "../../auth/withAuth";
import {
  AcceptStatementById,
  RejectStatementById,
  getStatementById,
  getStatements,
} from "../../api/api";
import Modal from "react-bootstrap/Modal";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import Gallery from "../gallery/gallery";
import withRouter from "../../auth/withRouter";

const statusDetails = {
  0: { phrase: "განხილვაში", color: "rgb(205, 123, 0)" },
  1: { phrase: "დასრულებული", color: "green" },
  5: { phrase: "გადახდილი", color: "green" },
  4: { phrase: "განთავსებული", color: "green" },
  10: { phrase: "დასადასტურებელი", color: "green" },
  15: { phrase: "გადასახდელი", color: "rgb(255,128,0)" },
  98: { phrase: "გაუქმებული", color: "rgb(205,0,0)" },
  99: { phrase: "უარყოფილი", color: "rgb(205,0,0)" },
};

class StatementsList extends Component {
  state = {
    statements: [],
    user: "",
    firstName: "",
    lastName: "",
    address: "",
    description: "",
    email: "",
    phoneNumber: "",
    id: "",
    wasteImages: [],
    dateCreated: "",
    status: "",
    price: null,
    isLoadingDetails: false,
    garbageWasteTypeNames: [],
    isModalVisible: false,
    isUploadModalVisible: false,
    isChangeDescriptionModalVisible: false,
    isChangeCategoriesModalVisible: false,
  };

  toggleStatementModal = () => {
    this.setState((prevState) => ({
      isModalVisible: !prevState.isModalVisible,
    }));
  };

  toggleChangeDescriptionModal = () => {
    this.setState((prevState) => ({
      isChangeDescriptionModalVisible:
        !prevState.isChangeDescriptionModalVisible,
      isModalVisible: false,
    }));
  };

  toggleUploadModal = () => {
    this.setState((prevState) => ({
      isUploadModalVisible: !prevState.isUploadModalVisible,
      isModalVisible: false,
    }));
  };

  toggleChangeCategoriesModal = () => {
    this.setState((prevState) => ({
      isChangeCategoriesModalVisible: !prevState.isChangeCategoriesModalVisible,
      isModalVisible: false,
    }));
  };

  closeUploadModalAndReopenStatement = () => {
    this.setState({
      isUploadModalVisible: false,
      isModalVisible: true,
    });
  };

  closeChangeDescriptionModalAndReopenStatement = () => {
    this.setState({
      isChangeDescriptionModalVisible: false,
      isModalVisible: true,
    });
  };

  closeChangeCategoriesModalAndReopenStatement = () => {
    this.setState({
      isChangeCategoriesModalVisible: false,
      isModalVisible: true,
    });
  };

  async componentDidMount() {
    console.log("ComponentDidMount", this.props);
    try {
      let statementsFromService = await getStatements(this.props.token);
      this.setState({ statements: statementsFromService });
    } catch (err) {
      console.log("401");
    }
  }

  onUserStatementAccept = async (statementId) => {
    console.log("statementId", statementId);
    await AcceptStatementById(this.props.token, statementId);
    let statementsFromService = await getStatements(this.props.token);
    this.setState({ statements: statementsFromService });
  };

  onUserStatementReject = async (statementId) => {
    console.log("statementId", statementId);
    await RejectStatementById(this.props.token, statementId);
    let statementsFromService = await getStatements(this.props.token);
    this.setState({ statements: statementsFromService });
  };

  onUserStatementInfo = async (event, id) => {
    this.setState({ isLoadingDetails: true });
    try {
      let statementDetails = await getStatementById(this.props.token, id);
      console.log("Fetched statement details:", statementDetails);
      this.setState({
        id: id,
        firstName: statementDetails.user.firstName,
        lastName: statementDetails.user.lastName,
        phoneNumber: statementDetails.user.phoneNumber,
        email: statementDetails.user.email,
        address: statementDetails.address,
        description: statementDetails.description,
        status: statementDetails.status,
        price: statementDetails.price,
        dateCreated: this.formatDate(statementDetails.dateCreated),
        wasteImages: statementDetails.wasteImages,
        garbageWasteTypeNames: statementDetails.garbageWasteTypes.map(
          (x) => x.garbageWasteType.name
        ),
        isLoadingDetails: false,
        isModalVisible: true,
      });
    } catch (error) {
      console.log("Error fetching statement details:", error);
      this.setState({ isLoadingDetails: false });
    }
  };

  formatDate(dateString) {
    const dateObj = new Date(dateString);
    const year = dateObj.getFullYear();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
    const day = dateObj.getDate().toString().padStart(2, "0");
    return `${month} / ${day} / ${year}`;
  }

  renderStatus = (status) => {
    const { phrase, color } = statusDetails[status] || {
      phrase: "Unknown",
      color: "black",
    };
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span style={{ fontWeight: "normal" }}>სტატუსი:&nbsp;</span>
        <span style={{ color: color }}>{phrase}</span>
      </div>
    );
  };

  render() {
    return (
      <>
        <Modal
          show={this.state.isModalVisible}
          onHide={this.toggleStatementModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header style={{ borderBottom: "none" }}>
            <Modal.Title style={{ textAlign: "center", width: "100%" }}>
              სრული განცხადება
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ListGroup>
              <ListGroup.Item>
                სახელი: <strong>{this.state.firstName}</strong>
              </ListGroup.Item>
              <ListGroup.Item>
                გვარი: <strong>{this.state.lastName}</strong>
              </ListGroup.Item>
              <ListGroup.Item>
                ელ-ფოსტა: <strong>{this.state.email}</strong>
              </ListGroup.Item>
              <ListGroup.Item>
                ტელეფონის ნომერი: <strong>{this.state.phoneNumber}</strong>
              </ListGroup.Item>
              <ListGroup.Item>
                შექმნის თარიღი: <strong>{this.state.dateCreated}</strong>
              </ListGroup.Item>
              <ListGroup.Item>
                მისამართი: <strong>{this.state.address}</strong>
              </ListGroup.Item>
              <ListGroup.Item>
                აღწერა: <strong>{this.state.description}</strong>
              </ListGroup.Item>
              <ListGroup.Item>
                კატეგორიები:
                <ul className="left-aligned-categories">
                  <strong>
                    {this.state.garbageWasteTypeNames.map((type, index) => (
                      <ul key={index}>
                        {index + 1}. {type}
                      </ul>
                    ))}
                  </strong>
                </ul>
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>{this.renderStatus(this.state.status)}</strong>
              </ListGroup.Item>
              <ListGroup.Item>
                ფასი:{" "}
                <strong>
                  {this.state.price ? `${this.state.price} ₾` : ""}
                </strong>
              </ListGroup.Item>
              <ListGroup.Item>
                ფოტომასალა:
                <Gallery
                  images={this.state.wasteImages}
                  token={this.props.token}
                />
              </ListGroup.Item>
              <ListGroup.Item>
                <div className="row" style={{ justifyContent: "center" }}>
                  <Button
                    variant="success"
                    size="sm"
                    className=" col-sm-3 statementInfoButtons"
                    onClick={this.toggleUploadModal}
                  >
                    ფოტო(ები)ს დამატება
                  </Button>
                  <div className="col-sm-1"></div>
                  <Button
                    variant="success"
                    size="sm"
                    className="statementInfoButtons col-sm-3"
                    onClick={this.toggleChangeDescriptionModal}
                  >
                    აღწერის შეცვლა
                  </Button>
                  <div className="col-sm-1"></div>
                  <Button
                    variant="success"
                    size="sm"
                    className="col-sm-3 statementInfoButtons"
                    onClick={this.toggleChangeCategoriesModal}
                  >
                    კატეგორიების შეცვლა
                  </Button>
                </div>
              </ListGroup.Item>
            </ListGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => this.setState({ isModalVisible: false })}
            >
              დახურვა
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.isUploadModalVisible}
          onHide={this.closeUploadModalAndReopenStatement}
          size="md"
        >
          <Modal.Header>
            <Modal.Title>ატვირთეთ ფოტომასალა</Modal.Title>
          </Modal.Header>
          <Modal.Body></Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              onClick={this.closeUploadModalAndReopenStatement}
            >
              ატვირთვა
            </Button>
            <Button
              variant="secondary"
              onClick={this.closeUploadModalAndReopenStatement}
            >
              დახურვა
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.isChangeDescriptionModalVisible}
          onHide={this.closeChangeDescriptionModalAndReopenStatement}
          size="md"
        >
          <Modal.Header>
            <Modal.Title>აღწერის შეცვლა</Modal.Title>
          </Modal.Header>
          <Modal.Body></Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              onClick={this.closeChangeDescriptionModalAndReopenStatement}
            >
              შეცვლა
            </Button>
            <Button
              variant="secondary"
              onClick={this.closeChangeDescriptionModalAndReopenStatement}
            >
              დახურვა
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.isChangeCategoriesModalVisible}
          onHide={this.closeChangeCategoriesModalAndReopenStatement}
          size="md"
        >
          <Modal.Header>
            <Modal.Title>კატეგორიების შეცვლა</Modal.Title>
          </Modal.Header>
          <Modal.Body></Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              onClick={this.closeChangeCategoriesModalAndReopenStatement}
            >
              შეცვლა
            </Button>
            <Button
              variant="secondary"
              onClick={this.closeChangeCategoriesModalAndReopenStatement}
            >
              დახურვა
            </Button>
          </Modal.Footer>
          .
        </Modal>

        <div className="object-cards">
          <ul className="statementslist">
            {this.state.statements.map((item) => (
              <Statements
                key={item.id}
                Statements={item}
                onAcceptClick={() => this.onUserStatementAccept(item.id)}
                onDeleteClick={() => this.onUserStatementReject(item.id)}
                onInfoClick={(event) =>
                  this.onUserStatementInfo(event, item.id)
                }
              />
            ))}
          </ul>
        </div>
      </>
    );
  }
}

export default withAuth(withRouter(StatementsList));
