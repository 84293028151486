import {
  PERSON_LOAD_BEGIN,
  PERSON_LOAD_SUCCESS,
  PERSON_LOAD_ERROR
} from "../actions/ActionTypes";

const initialState = {
  person: null,
  success: true,
  loader: false,
  error: null,
};

const personReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case PERSON_LOAD_BEGIN:
      return { ...state, loader: true, error: null };
    case PERSON_LOAD_SUCCESS:
      console.log("personInReducer",payload.result)
      return { ...state, person: payload.result, loader: false, error: null };
    case PERSON_LOAD_ERROR:
      return { ...state, error: payload, loader: false };
    default:
      return state;
  }
};

export default personReducer;
