import {
    PENSION_STATEMENT_BY_ID_LOAD_BEGIN,
    PENSION_STATEMENT_BY_ID_LOAD_SUCCESS,
    PENSION_STATEMENT_BY_ID_LOAD_ERROR
  } from "../actions/ActionTypes";
  
  const initialState = {
    statement: null,
    success: false,
    loader: false,
    error: null,
  };
  
  const pensionStatementByIdReducer = (state = initialState, action) => {
    const { type, payload } = action;
  
    switch (type) {
      case PENSION_STATEMENT_BY_ID_LOAD_BEGIN:
        return { ...state, loader: true, error: null };
      case PENSION_STATEMENT_BY_ID_LOAD_SUCCESS:
        console.log("pensionStatementByIdReducer",payload.result)
        return { ...state, statement: payload.result,success:true, loader: false, error: null };
      case PENSION_STATEMENT_BY_ID_LOAD_ERROR:
        return { ...state, error: payload, loader: false };
      default:
        return state;
    }
  };
  
  export default pensionStatementByIdReducer;
  