import React from "react";
import "./MainPage.css";
import { useState } from "react";
import { Link } from "react-router-dom";
import Header from "../header/Header";
import withRouter from "../../auth/withRouter";
import StatementsList from "../statementsList/statementsList";

function MainPage() {
  return <></>;
}

export default withRouter(MainPage);
// import React from "react";
// import { connect } from "react-redux";
// import Swal from "sweetalert2";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { Button, Form, InputGroup, Modal, PageItem } from "react-bootstrap";
// import {
//   addPensionStatement,
//   getPersonCRAData,
//   getPensionStatementById,
// } from "../../actions/Actions";
// import {
//   faChevronRight,
//   faChevronDown,
//   faBars,
//   // faDownload,
//   faDownload,
//   faPencilAlt,
//   faTrash
//   // f33d,
// } from "@fortawesome/free-solid-svg-icons";
// import {
//   faEye,
//   faPrint,
//   faAngleUp,
//   faAngleDown,

//   // faArrowDownToLine,

//   // faDownToLine,
//   // faPencil,
// } from "@fortawesome/fontawesome-free-solid";
// import { authorize, loggedOut } from "../../auth/authService";
// import { withRouter, Link } from "react-router-dom";
// import { homepage } from "../../constants";
// import withAuth from "../../auth/withAuth";
// import DropdownButton from "react-bootstrap/Dropdown";
// import Dropdown from "react-bootstrap/Dropdown";
// import { pensionTypes, operationStatuses } from "../../constants";
// import {
//   getPerson,
//   getPensionStatementsByUserId,
// } from "../../actions/Actions";
// import "./MainPage.css";

// class MainPage extends React.Component {
//   state = {
//     //privateNumber: "01001000002",
//     // email: "prodigygiga@gmail.com",
//     // documentNumber: "IA233233",
//     // citizenship: "საქართველო",
//     // firstName: "g",
//     // lastName: "გრიგალაშვილი",
//     // region: "თბილისი",
//     // birthDate: "1993-04-02",
//     // city: "თბილისი",
//     // gender: "მამრობითი",
//     // legalAddress: "ვარკეთილი 3",
//     // mobileNumber: "595038963",
//     isLoginButtonEnabled: false,
//     showAddRequestModal: false,
//     actionType: 1,
//     collapsedState: new Map(),
//     privateNumber: "",
//     comment: "",
//     statementStatus: "დაგენერირდება შენახვისას",
//     statementNumber: "დაგენერირდება შენახვისას",
//     statementDate: "დაგენერირდება შენახვისას",
//     hasRepresentative: false,
//     representativePrivateNumber: "",
//     representativeLastName: "",
//     representativePhoneNumber: "",
//     representativeComment: "",
//     requests: [],
//     requestPensionTypeId: "",
//     requestOperationStatusId: "",
//     requestPensionType: "",
//     requestOperationStatus: "",
//     requestStatus: "...",
//     requestResult: "...",
//     requestNote: "",
//     documents: [],
//     document: null,
//     documentName: "",
//     loginData: null,
//     registerButtonDisable: false,
//     showAddRequestModal: false,
//     showAddFileModal: false,
//     editStatus: 0,
//   };

//   async componentDidMount() {
//    console.log("MainPageCDM");
//     const { token } = this.props;
//     const statementId = this.props.match.params.id;
//     // await this.props.getPerson(token);
//     // await this.props.getPensionStatementsByUserId(
//     //   token
//     // );
//     // if (statementId != null) {
//     //   await this.props.getPensionStatementById(token, statementId);
//     //   let requestsWithComments = new Map();
//     //   this.props.statement &&
//     //     this.props.statement.requests.map((item) => {
//     //       requestsWithComments.set("#collapsed-" + item.id, false);
//     //     });
//     // console.log("this.props.statements", this.props.statements);
//     // let statementWithStates = new Map();
//     // this.props.statements &&
//     //   this.props.statements.map((item) => {
//     //     statementWithStates.set("#collapsed-" + item.id, false);
//     //   });
//     // this.setState({ collapsedState: statementWithStates, requestsWithComments, editStatus: 1 }, () =>
//     //   console.log("collapsedState", this.state.collapsedState)
//     // );
//   }
//   // async componentDidUpdate(prevProps, prevState) {
//   //   console.log(
//   //     "prevProps.statementSuccessById",
//   //     prevProps.statementSuccessById
//   //   );
//   //   console.log(
//   //     "this.props.statementSuccessByid",
//   //     this.props.statementSuccessById
//   //   );
//   //   console.log("prevState", prevState.editStatus);
//   //   console.log("prevState", this.state.editStatus);
//   //   if (
//   //     prevProps.statementSuccessById != this.props.statementSuccessById ||
//   //     prevState.editStatus != this.state.editStatus
//   //   ) {
//   //     if (this.state.editStatus === 1) {
//   //       this.setState({
//   //         requests: [...this.props.statement.requests],
//   //         statementStatus: this.props.statement.statementStatus,
//   //         statementDate: this.props.statement.registrationDate,
//   //         statementNumber: this.props.statement.registrationNumber,
//   //       });
//   //     }
//   //   }
//   // }

//   handleChange = (event) => {
//     const { name, value } = event.target;
//     this.setState({ [name]: value }, () => {
//       //this.validateSms()
//       this.validateForm();
//     });
//   };

//   handleCollapseClick = (id) => {
//     console.log("id", id);
//     let collapState = this.state.collapsedState.get(id);
//     let collapsedState = this.state.collapsedState;

//     collapsedState.set(id, !collapState);

//     this.setState({ collapsedState }, () =>
//       console.log("collapsedState", collapsedState)
//     );
//   };

//   handleCheckChange = (event) => {
//     const { name, value, checked, type } = event.target;
//     this.setState({ [name]: type === "checkbox" ? checked : value });
//   };
//   handleshowAddRequestModal= () => {
//     this.setState({
//       showAddRequestModal:true,
//     })
//   }

//   handleStatementAddition = async (event) => {
//     event.preventDefault();

//     // const form = event.currentTarget;
//     // if (form.checkValidity() && this.validateForm()) {
//     //const { privateNumber, phoneNumber, smsCode } = this.state;
//     const { token } = this.props;
//     await this.props.addPensionStatement(token, this.state);
//     const { success, messages } = this.props.addPensionStatementResult;
//     console.log("ttt", this.props.addPensionStatementResult);
//     if (success) {
//       Swal.fire("განაცხადი წარმატებით დაემატა!");

//       this.props.router.navigate(`${homepage}/landingpage`);
//     } else {
//       console.log("ttt", this.props.addPensionStatementResult);
//       const mst = messages.join("\n*");
//       Swal.fire(mst);
//     }
//     // }
//   };

//   handleChangeWithLabel = (event) => {
//     const { name, value } = event.target;
//     var index = event.nativeEvent.target.selectedIndex;
//     const label = event.nativeEvent.target[index].text;
//     console.log("lbl", label);
//     console.log("name", name);
//     if (name == "requestOperationStatusId") {
//       this.setState({ requestOperationStatus: label });
//     }
//     if (name == "requestPensionTypeId") {
//       this.setState({ requestPensionType: label });
//     }
//     this.setState({ [name]: value }, () => {
//       this.validateForm();
//       console.log("my State", this.state);
//     });
//   };

//   handleContextMenu = (id) => {
//     console.log("statementId",id);
//     this.props.router.navigate(`${homepage}/editStatement/${id}`);
//   }
//   // validateSms = () => {
//   //   const { privateNumber, phoneNumber } = this.state
//   //   this.setState({
//   //     sendSmsButton: !(privateNumber.length === 11 && phoneNumber.length === 9),
//   //   })
//   // }
//   handleRequestAddition = async (event) => {
//     event.preventDefault();
//     let requestsArr = [...this.state.requests];
//     requestsArr.push({
//       requestPensionTypeId: this.state.requestPensionTypeId,
//       requestOperationStatusId: this.state.requestOperationStatusId,
//       requestStatus: this.state.requestStatus,
//       requestResult: this.state.requestResult,
//       requestNote: this.state.requestNote,
//       requestPensionType: this.state.requestPensionType,
//       requestOperationStatus: this.state.requestOperationStatus,
//       documents: this.state.documents,
//     });
//     this.setState({ requests: requestsArr }, () =>
//       console.log("rqs", this.state.requests)
//     );
//   };

//   handleRequestAdditionModal = async (event) => {
//     event.preventDefault();
//     this.setState({ showAddRequestModal: true });
//   };
//   handleAddFileModal = async (event) => {
//     event.preventDefault();
//     this.setState({ showAddFileModal: true });
//   };
//   handleAddFile = async (event) => {
//     event.preventDefault();

//     var docs = this.state.documents;
//     docs.push({ Name: this.state.documentName, FormFile: this.state.document });
//     this.setState(
//       {
//         documentName: "",
//         document: null,
//         documents: docs,
//         showAddFileModal: false,
//       },
//       () => console.log("state", this.state)
//     );
//   };
//   handleGetPersonCraData = async (event) => {
//     event.preventDefault();
//     const { token } = this.props;
//     await this.props.getPersonCRAData(
//       token,
//       this.state.representativePrivateNumber,
//       this.state.representativeLastName
//     );
//   };

//   onFileChange = (event) => {
//     console.log(event.target.files[0]);
//     // event.preventDefault();
//     // Update the state
//     this.setState({ document: event.target.files[0] }, () =>
//       console.log("docccc", this.state.document)
//     );
//   };
//   validateForm = () => {
//     const { privateNumber, phoneNumber, smsCode, password, actionType, lastName, birthDate, documentNumber, email } =
//       this.state;
//     return (
//       privateNumber.length === 11 &&
//       phoneNumber.length === 9 &&
//       lastName.length > 0 &&
//       smsCode.length === 6 &&
//       password.length > 0
//     );
//   };

//   render() {
//     const {
//       privateNumber,
//       comment,
//       //representative
//       hasRepresentative,
//       representativePrivateNumber,
//       representativeLastName,
//       representativePhoneNumber,
//       representativeComment,
//       requests,
//       requestNote,
//       requestStatus,
//       requestResult,
//       showAddRequestModal,
//       showAddFileModal,
//       requestPensionTypeId,
//       requestOperationStatusId,
//       documents,
//       document,
//       documentName,
//       statementNumber,
//       statementDate,
//       statementStatus,
//       editStatus,
//       collapsedState,
//     } = this.state;

//     console.log("person",this.props.person);
//     return (
//       <>
//         <div className="main-page">
//           <div className="MotxovnisDamateba">
//           <Modal
//           size="lg"
//           show={showAddRequestModal}
//           centered
//           onHide={() => {
//             this.setState({ showAddRequestModal: false });
//           }}
//         >
//           <Modal.Header closeButton></Modal.Header>
//           <Modal.Body>
//             <Form
//               noValidate

//               // onSubmit={this.handleStatementAddition}
//             >
//               <div className="card">
//                 <div className="card-body">
//                   <h4>მოთხოვნის დამატება</h4>
//                   <hr
//                     style={{ backgroundColor: "#d88500", height: "2px" }}
//                   ></hr>
//                   <div className="container-fluid">
//                     <div className="row">
//                       <div className="col col-6 ">
//                         <p className="orange-color">შენიშვნა</p>
//                         <Form.Group controlId="validationForm33">
//                           <div className="input-group">
//                             <select
//                               className="custom-select"
//                               onChange={this.handleChangeWithLabel}
//                               name="requestPensionTypeId"
//                             >
//                               <option value="">მოთხოვნა</option>
//                               {pensionTypes &&
//                                 pensionTypes.map((item) => {
//                                   return (
//                                     <option key={item.id} value={item.id}>
//                                       {item.name}
//                                     </option>
//                                   );
//                                 })}
//                             </select>
//                           </div>
//                         </Form.Group>
//                       </div>
//                       <div className="col col-6 ">
//                         <p className="orange-color">მოთხოვნის ტიპი</p>
//                         <Form.Group controlId="validationForm33">
//                           <div className="input-group">
//                             <select
//                               className="custom-select"
//                               onChange={this.handleChangeWithLabel}
//                               name="requestOperationStatusId"
//                             >
//                               <option value="">ტიპი</option>
//                               {operationStatuses &&
//                                 operationStatuses.map((item) => {
//                                   return (
//                                     <option
//                                       key={item.id}
//                                       value={item.id}
//                                       label={item.name}
//                                     >
//                                       {item.name}
//                                     </option>
//                                   );
//                                 })}
//                             </select>
//                           </div>
//                         </Form.Group>
//                       </div>
//                     </div>
//                     <div className="row">
//                       <div className="col col-6 ">
//                         <p className="orange-color">მოთხოვნის სტატუსი</p>
//                         <p>{requestStatus}</p>
//                       </div>
//                       <div className="col col-6 ">
//                         <p className="orange-color">მოთხოვნის შედეგი</p>
//                         <p>{requestResult}</p>
//                       </div>
//                     </div>
//                     <div className="row">
//                       <div className="col col-12 ">
//                         <p className="orange-color">შენიშვნა</p>
//                         <Form.Group controlId="validationForm1">
//                           {/* <Form.Label className="font-weight-bold"></Form.Label> */}
//                           <Form.Control
//                             autoComplete="off"
//                             type="text"
//                             name="requestNote"
//                             placeholder="მიუთითეთ შენიშვნა"
//                             value={requestNote}
//                             onChange={this.handleChange}
//                           />
//                         </Form.Group>
//                       </div>
//                     </div>
//                     <br></br>
//                     <h5>თანმხლები დოკუმენტები</h5>
//                     <hr
//                       style={{ backgroundColor: "#d88500", height: "2px" }}
//                     ></hr>
//                     <button
//                       type="button"
//                       onClick={this.handleAddFileModal}
//                       className="btn orange-colored-button "
//                       // style={{ float: "right" }}
//                     >
//                       დოკუმენტის ატვირთვა
//                     </button>
//                     <div className="row object-cards">
//                       <div className="col col-12">
//                         <ul>
//                           {documents &&
//                             documents.map((item) => (
//                               <li key={item.Name}>
//                                 <div>
//                                   <div className="row">
//                                     <div className="col-md-12">{item.Name}</div>
//                                   </div>
//                                 </div>
//                               </li>
//                             ))}
//                         </ul>
//                       </div>
//                     </div>
//                     <br />
//                     <div className="form-group">
//                       <button
//                         className="btn orange-colored-button btn-block"
//                         onClick={this.handleRequestAddition}
//                       >
//                         თანხმობა
//                       </button>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </Form>
//           </Modal.Body>
//           {/* <Modal.Footer>
//             <Button variant="success" size="sm" onClick={this.handleLayersVisibility}>შენახვა</Button>
//           </Modal.Footer> */}
//         </Modal>
//           </div>
//           <div className="card">
//             {/* <div className="card card-header">
//                 ჩემი გასაცემლები ({this.props.success && this.props.person != null ?this.props.person.person.privateNumber:null})
//             </div> */}
//             <div className="card-body">
//               <h4>ბენეფიციარის პროფილი</h4>

//               <hr style={{ backgroundColor: "#d88500", height: "2px" }}></hr>
//               {this.props.person ? (
//                 <div className="container-fluid">
//                   <div className="row">
//                     <div className="col col-3">
//                       <img
//                         src={require("../../static/images/no_person.png")}
//                         className="card-img-top mx-auto d-block"
//                         style={{ width: 120, height: 120, float: "middle" }}
//                         alt="no_photo"
//                       />
//                       <hr></hr>
//                       <button className="btn btn-primary mx-auto d-block">
//                         პროფილის კორექტირება
//                       </button>
//                     </div>
//                     <div className="col col-9">
//                       <div className="row">
//                         <div className="col col-6">
//                           <u>პირადი ნომერი:</u>
//                           <p className="blue-p">
//                             {this.props.person.privateNumber}
//                           </p>
//                         </div>
//                         <div className="col col-6">
//                           <u>ელ.ფოსტა:</u>
//                           <p className="blue-p">{this.props.person.email}</p>
//                         </div>
//                       </div>
//                       <div className="row">
//                         <div className="col col-6">
//                           <u>პირადობის ნომერი:</u>
//                           <p className="blue-p">
//                             {this.props.person.documentNumber}
//                           </p>
//                         </div>
//                         <div className="col col-6">
//                           <u>მოქ. სტატუსი:</u>
//                           <p className="blue-p">
//                             {this.props.person.citizenship}
//                           </p>
//                         </div>
//                       </div>
//                       <div className="row">
//                         <div className="col col-6">
//                           <u>სახელი:</u>
//                           <p className="blue-p">
//                             {this.props.person.firstName}
//                           </p>
//                         </div>
//                         <div className="col col-6">
//                           <u>ქვეყანა:</u>
//                           <p className="blue-p">
//                             {this.props.person.citizenship}
//                           </p>
//                         </div>
//                       </div>
//                       <div className="row">
//                         <div className="col col-6">
//                           <u>გვარი:</u>
//                           <p className="blue-p">{this.props.person.lastName}</p>
//                         </div>
//                         <div className="col col-6">
//                           <u>რეგიონი:</u>
//                           <p className="blue-p">{this.props.person.region}</p>
//                         </div>
//                       </div>
//                       <div className="row">
//                         <div className="col col-6">
//                           <u>დაბ. თარიღი:</u>
//                           <p className="blue-p">
//                             {this.props.person.birthDate}
//                           </p>
//                         </div>
//                         <div className="col col-6">
//                           <u>ქალაქი:</u>
//                           <p className="blue-p">{this.props.person.city}</p>
//                         </div>
//                       </div>
//                       <div className="row">
//                         <div className="col col-6">
//                           <u>სქესი:</u>
//                           <p className="blue-p">{this.props.person.gender}</p>
//                         </div>
//                         <div className="col col-6">
//                           <u>მობილური:</u>
//                           <p className="blue-p">
//                             {this.props.person.mobileNumber}
//                           </p>
//                         </div>
//                       </div>
//                       <div className="row">
//                         <div className="col col-12">
//                           <u>რეგისტრაციის მისამართი:</u>
//                           <p className="blue-p">
//                             {this.props.person.legalAddress}
//                           </p>
//                         </div>
//                       </div>
//                       <div className="row">
//                         <div className="col col-12">
//                           <u>გასაცემლები:</u>
//                           {this.props.success && this.props.person != null ? (
//                             <div>
//                               {this.props.person.payoutsList.map((x, index) => (
//                                 <p className="blue-p" key={index}>
//                                   {x}
//                                 </p>
//                               ))}
//                             </div>
//                           ) : null}
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               ) : (
//                 <></>
//               )}
//             </div>
//           </div>
//           <br />
//           <div className="card">
//             <div className="card-body">
//               <h4>
//                 ბენეფიციარის განცხადებები
//                 <button
//                   type="button"
//                   onClick={this.handleshowAddRequestModal}
//                   className="btn orange-colored-button"
//                   style={{ float: "right" }}
//                 >
//                   ახალი განცხადება
//                 </button>
//               </h4>

//               <hr style={{ backgroundColor: "#d88500", height: "2px" }}></hr>
//               <div className="container-fluid object-cards">
//                 <div className="row object-cards-head">
//                   <div className="col-md-2">
//                     <b>პირადი ნომერი: </b>
//                   </div>
//                   <div className="col-md-2">
//                     <b>სახელი,გვარი: </b>
//                   </div>
//                   <div className="col-md-1">
//                     <b>რეგისტრაციის ნომერი: </b>
//                   </div>
//                   <div className="col-md-2">
//                     <b>განაცხადის ტიპი: </b>
//                   </div>
//                   <div className="col-md-2">
//                     <b>განაცხადის თარიღი: </b>
//                   </div>
//                   <div className="col-md-2">
//                     <b>სტატუსი: </b>
//                   </div>
//                   <div className="col-md-1">
//                     <b> </b>
//                   </div>
//                 </div>
//                 <ul>
//                   {this.props.statements &&
//                     this.props.statements.map((item) => (
//                       <li key={item.id}>
//                         <div>
//                           <div className="row">
//                             {/* <div className="col-md-1">
//                               <div
//                                 onClick={() =>
//                                   this.handleCollapseClick(
//                                     "#collapsed-" + item.id
//                                   )
//                                 }
//                               >
//                                 {collapsedState.get("#collapsed-" + item.id) ? (
//                                   <p>
//                                     <FontAwesomeIcon icon={faChevronDown} />
//                                   </p>
//                                 ) : (
//                                   <p>
//                                     <FontAwesomeIcon icon={faChevronRight} />
//                                   </p>
//                                 )}
//                               </div>
//                             </div> */}

//                             <div className="col-md-2">
//                               <div
//                                 onClick={() =>
//                                   this.handleCollapseClick(
//                                     "#collapsed-" + item.id
//                                   )
//                                 }
//                               >
//                                 {collapsedState.get("#collapsed-" + item.id) ? (
//                                   <p>
//                                     <FontAwesomeIcon icon={faChevronDown} />
//                                     &nbsp;&nbsp;&nbsp;{item.beneficiary.privateNumber}
//                                   </p>
//                                 ) : (
//                                   <p>
//                                     <FontAwesomeIcon icon={faChevronRight} />
//                                     &nbsp;&nbsp;&nbsp;{item.beneficiary.privateNumber}
//                                   </p>
//                                 )}

//                               </div>

//                             </div>
//                             <div className="col-md-2">
//                               {item.beneficiary.firstName +
//                                 " " +
//                                 item.beneficiary.lastName}
//                             </div>
//                             <div className="col-md-1">
//                               {item.registrationNumber}
//                             </div>
//                             <div className="col-md-2">{item.statementType}</div>
//                             <div className="col-md-2">
//                               {item.registrationDate}
//                             </div>
//                             <div className="col-md-2">
//                               {item.statementStatus}
//                             </div>
//                             <div className="col-md-1">
//                             <DropdownButton
//                                 align="end"
//                                 title="Dropdown end"
//                                 id="dropdown-menu-align-end"
//                               >
//                                 <Dropdown.Toggle variant="outline-secondary">
//                                   <i className="fa fa-bars"></i>
//                                   <FontAwesomeIcon icon={faBars} />
//                                 </Dropdown.Toggle>

//                                 <Dropdown.Menu style={{ width: "140px" }}>
//                                   <div style={{ paddingLeft: "9px" }}>
//                                     <div onClick={() => this.handleContextMenu(item.id)}>
//                                       <FontAwesomeIcon
//                                         icon={faPencilAlt}
//                                         style={{ color: "#507BE4" }}
//                                       />
//                                       <b
//                                         style={{
//                                           paddingLeft: "9px",
//                                           color: "#707070",
//                                         }}
//                                       >
//                                         რედაქტირება
//                                       </b>
//                                     </div>

//                                     <div style={{ paddingTop: "2px" }}>
//                                       <FontAwesomeIcon
//                                         icon={faTrash}
//                                         style={{ color: "#507BE4" }}
//                                       />
//                                       <b
//                                         style={{
//                                           paddingLeft: "9px",
//                                           color: "#707070",
//                                         }}
//                                       >
//                                         წაშლა
//                                       </b>
//                                     </div>
//                                   </div>
//                                 </Dropdown.Menu>
//                               </DropdownButton>
//                             </div>
//                           </div>
//                           <div
//                             className={
//                               collapsedState.get("#collapsed-" + item.id)
//                                 ? "collapse show"
//                                 : "collapse"
//                             }
//                             id={"collapsed-" + item.id}
//                           >
//                             <div className="row">

//                               <div className="col col-12">
//                                 <ul>
//                                   {item.requests &&
//                                     item.requests.map((item) => (
//                                       <li key={item.id}>
//                                         <div className="row">
//                                           <div className="col-md-6">
//                                             <p>
//                                               <b>მოთხოვნის სახეობა: </b>
//                                               {item.pensionType.name}
//                                             </p>
//                                             <p>
//                                               <b>მოთხოვნის ტიპი: </b>
//                                               {item.operationStatus.name}
//                                             </p>
//                                             <p>
//                                               <b>მოთხოვნის სტატუსი: </b>
//                                               {item.requestStatus}
//                                             </p>
//                                           </div>

//                                           <div className="col-md-6">
//                                             <p>
//                                               <b>მოთხოვნის შედეგი: </b>
//                                             </p>
//                                             <p>
//                                               <b>გადავადების სტატუსი: </b>
//                                               {item.postponeDate !=
//                                               "0001-01-01T00:00:00"
//                                                 ? item.postponeDate
//                                                 : ""}
//                                             </p>
//                                           </div>
//                                         </div>
//                                       </li>
//                                     ))}
//                                 </ul>
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       </li>
//                     ))}
//                 </ul>
//               </div>
//             </div>
//           </div>

//           {/* <Pager /> */}
//         </div>
//       </>
//     );
//   }
// }

// function mapStateToProps({
//   personReducer: { person, success },
//   pensionStatementsReducer: { statements },
// }) {
//   return { person, success, statements };
// }

// export default withRouter(withAuth(
//   connect(mapStateToProps, { getPerson, getPensionStatementsByUserId })(
//     MainPage
//   )
// ));
