import React from "react";
import "./ContactPage.css"; // Make sure to create this CSS file and import it here

import OLMap from "../maps/map1.js";
import OLMap2 from "../maps/map2.js";
import withAuth from "../../auth/withAuth.js";
import withRouter from "../../auth/withRouter.js";

function ContactPage() {
  // Example contact data, replace with data from your backend or state management
  // const contactData1 = {
  //   ტელეფონი: "+995 32 2619050",
  //   ფოსტა: "info@tbsg.ge",
  //   მისამართი: "კახეთის გზატკეცილი N67",
  // };

  return (
    <>
      <div className="main-contact-info">
        <h1 className="contact">კონტაქტი</h1>
        <h2 className="phone">
          ცხელი ხაზი: <a href="tel:+995322619050">+995 32 2619050</a>
        </h2>
        <h5 className="thirdphone">
          კანცელარია: <a href="tel:+995322611927">+995 32 2611927</a>
        </h5>
        <h4 className="secondphone">
          ნარჩენების მართვის სამსახური:{" "}
          <a href="tel:+995322611918">+995 32 2611918</a>
        </h4>
        <h3 className="mail">
          ელ-ფოსტა: <a href="mailto:info@tbsg.ge">info@tbsg.ge</a>
        </h3>
      </div>

      <div className="contact-container" style={{ marginBottom: "25px" }}>
        <div className="contact-container-first">
          <h2 className="main-office">ცენტრალური ოფისი</h2>
          <h3 className="main-address">მისამართი: კახეთის გზატკეცილი N67</h3>
          <OLMap />
        </div>
        <div className="contact-container-second">
          <h2 className="main-office">დასუფთავების სამსახური</h2>
          <h3 className="main-address">მისამართი: ბელიაშვილის ქ. N119-121</h3>
          <OLMap2 />
        </div>
      </div>
    </>
  );
}

export default withAuth(withRouter(ContactPage));
