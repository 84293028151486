import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { homepage } from "../../constants";
import { Button, Container, Nav, Navbar } from "react-bootstrap";
import { ImExit } from "react-icons/im";
import withAuth from "../../auth/withAuth";
import withRouter from "../../auth/withRouter";
import "./Header.css";

function Header(props) {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const navigate = useNavigate();

  const navigateToHome = () => {
    navigate(`${homepage}/main-page`);
    setIsNavExpanded(false);
  };

  const handleContactClick = () => {
    navigate(`${homepage}/contact`);
    setIsNavExpanded(false);
  };

  const handleNewRequestClick = () => {
    navigate(`${homepage}/new-request`);
    setIsNavExpanded(false);
  };

  const handleMyRequestsclick = () => {
    navigate(`${homepage}/my-requests`);
    setIsNavExpanded(false);
  };

  const handleLogOutclick = () => {
    console.log(props);

    props.logOut();

    navigate(`${homepage}/login`);
    setIsNavExpanded(false);
  };

  const handleNavbarToggle = () => setIsNavExpanded(!isNavExpanded);

  return (
    <>
      <Navbar
        className="headernav"
        variant="light"
        expand="md"
        expanded={isNavExpanded}
      >
        <Container fluid>
          <Navbar.Brand>
            <img
              className="logo"
              alt="tbilservisgroup"
              src={`${process.env.PUBLIC_URL}/logo.png`}
              height={40}
              width={80}
              onClick={navigateToHome}
            />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="navbarScroll"
            onClick={handleNavbarToggle}
          />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: "100px", color: "#595c5e" }}
            >
              <Nav.Link onClick={handleNewRequestClick}>
                <b>ახალი განცხადება</b>
              </Nav.Link>
              <Nav.Link onClick={handleMyRequestsclick}>
                <b>ჩემი განცხადებები</b>
              </Nav.Link>
              <Nav.Link onClick={handleContactClick}>
                <b>კონტაქტი</b>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>

          <Navbar.Collapse className="justify-content-end">
            <Button
              className="exitbutton"
              variant="light"
              onClick={handleLogOutclick}
            >
              <ImExit />
            </Button>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default withAuth(Header);
