import React from 'react'
import { connect } from 'react-redux'
import { Button } from 'react-bootstrap'
import Swal from 'sweetalert2'
import { sendSms } from '../../../actions/Actions'

class SmsControl extends React.Component {
  state = {
    smsCode: '',
    smsData: null,
    smsSecconds: 60,
    smsShow: false,
    smsInterval: null,
  }

  startTimer = () => {
    if (this.state.smsSecconds === 0) {
      clearInterval(this.state.smsInterval)
    }
    this.setState({ smsShow: true })
    const smsInterval = setInterval(
      () =>
        this.setState({
          smsSecconds: this.state.smsSecconds - 1,
        }),
      1000
    )
    this.setState({ smsInterval })
  }

  //registration Removed
  handleSmsClick = async () => {
    this.startTimer()
    console.log("props",this.props)
    const { privateNumber,
    firstName,
    lastName,
    birthDate,
    email,
    phoneNumber,
    password,
    confirmedPassword,
    actionType } = this.props
    await this.props.sendSms({ privateNumber,
      firstName,
      lastName,
      birthDate,
      email,
      phoneNumber,
      password,
      confirmedPassword,
      actionType })
    if (this.props.smsResult) {
      const { messages, success } = this.props.smsResult
      if (!success) this.resetTimer()
      const mst = messages.join("\n*")
      Swal.fire(mst);
    }
  }

  resetTimer = () => {
    this.setState({ smsSecconds: 60, smsShow: false })
    clearInterval(this.state.smsInterval)
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.smsSecconds === 0 &&
      prevState.smsSecconds !== this.state.smsSecconds
    ) {
      this.resetTimer()
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.smsInterval)
  }

  timerControl = () => {
    const { smsShow } = this.state
    if (smsShow) {
      return (
        <div className='btn btn-outline-dark sms-button'>
          {this.state.smsSecconds}
        </div>
      )
    }
    return (
      <Button
        variant='outline-secondary sms-button'
        disabled={this.props.disabled}
        onClick={this.handleSmsClick}
        style={{ zIndex: 0 }}
      >
        სმს გაგზავნა
      </Button>
    )
  }

  render() {
    return <>{this.timerControl()}</>
  }
}
function mapStateToProps({ smsReducer: { smsResult } }) {
  return {
    smsResult,
  }
}
export default connect(mapStateToProps, { sendSms })(SmsControl)
