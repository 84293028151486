import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useParams } from "react-router-dom";
import {
  getStatementById,
  updateStatementAPI,
  uploadFilesAPI,
  setSeenStatus,
  uploadWastePlacementFilesAPI,
} from "../../api/api";
import ListGroup from "react-bootstrap/ListGroup";
import Gallery from "../gallery/gallery";
import "./StatementInfo.css";
import withAuth from "../../auth/withAuth";
import { AiOutlineClose, AiOutlineCheck } from "react-icons/ai";
import MessagePage from "../messages/MessagePage";
import { homepage } from "../../constants";
import MapComponent from "../maps/mapcomponent";
import IconsChecked1 from "../../icons/Icons-01.png";
import IconsUnchecked1 from "../../icons/Icons-02.png";
import IconsUnchecked2 from "../../icons/Icons-04.png";
import IconsChecked2 from "../../icons/Icons-03.png";
import IconsUnchecked3 from "../../icons/Icons-06.png";
import IconsChecked3 from "../../icons/Icons-05.png";
import IconsUnchecked5 from "../../icons/Icons-10.png";
import IconsChecked5 from "../../icons/Icons-09.png";
import { Row, Form, Col } from "react-bootstrap";
import moment from "moment";
import withRouter from "../../auth/withRouter";
import { tbilisiRegions, garbageVolumes } from "../../constants";

const statusDetails = {
  0: { phrase: "განხილვაში", color: "rgb(205, 123, 0)" },
  1: { phrase: "დასრულებული", color: "green" },
  5: { phrase: "გადახდილი", color: "green" },
  4: { phrase: "განთავსებული", color: "green" },
  10: { phrase: "დასადასტურებელი", color: "green" },
  15: { phrase: "გადასახდელი", color: "rgb(255,128,0)" },
  98: { phrase: "გაუქმებული", color: "rgb(205,0,0)" },
  99: { phrase: "უარყოფილი", color: "rgb(205,0,0)" },
};

const garbageWasteTypes = [
  {
    id: "22268a71-9cad-474e-a789-5975d9649451",
    name: `სამშენებლო მასალა ტომრებით: ბეტონი, გაჯი, მეტლახი და ა.შ.`,
  },
  {
    id: "d1da70b0-9d61-4822-a0b8-1895b14ec8bf",
    name: "სამშენებლო ხის მასალა: კარი, ფანჯარა და ა.შ.",
  },
  {
    id: "b2e2dba1-bbb6-4ad1-bec0-f35df8d57ef3",
    name: "საყოფაცხოვრებო ავეჯი: კარადა, საწოლი და ა.შ.",
  },
  {
    id: "958f8e62-fc36-4cb6-99bc-e4e2628706cb",
    name: "ხის ტოტები / დასუფთავებული კერძო ბაღის ნარჩენები",
  },
];

const iconMap = {
  "22268a71-9cad-474e-a789-5975d9649451": {
    unchecked: IconsUnchecked1,
    checked: IconsChecked1,
  },
  "d1da70b0-9d61-4822-a0b8-1895b14ec8bf": {
    unchecked: IconsUnchecked2,
    checked: IconsChecked2,
  },
  "b2e2dba1-bbb6-4ad1-bec0-f35df8d57ef3": {
    unchecked: IconsUnchecked3,
    checked: IconsChecked3,
  },
  "958f8e62-fc36-4cb6-99bc-e4e2628706cb": {
    unchecked: IconsUnchecked5,
    checked: IconsChecked5,
  },
};

class StatementInfoClass extends Component {
  state = {
    user: {
      id: "",
    },
    firstName: "",
    lastName: "",
    address: "",
    description: "",
    email: "",
    phoneNumber: "",
    id: "",
    wasteImages: [],
    dateCreated: "",
    status: "",
    price: null,
    isLoadingDetails: false,
    garbageWasteTypeNames: [],
    GarbageWasteTypeIds: [],
    isEditing: false,
    showUploadModal: false,
    selectedFiles: [],
    uploadAttemptedWithoutFiles: false,
    uploadSuccessful: false,
    showMessageModal: false,
    showSuccessModal: false,
    originalState: {},
    showMapModal: false,
    longitude: 0,
    latitude: 0,
    notSeenCommentCount: null,
    wasteCollectionExpDate: null,
    RegionId: "",
    SelectedVolume: "",
  };

  async componentDidMount() {
    await this.fetchStatementDetails();
  }

  fetchStatementDetails = async () => {
    const { id } = this.props.params;
    this.setState({ isLoadingDetails: true });
    try {
      let statementDetails = await getStatementById(this.props.token, id);
      const formattedWasteCollectionDate = statementDetails.wasteCollectionDate
        ? moment(statementDetails.wasteCollectionDate).format("MM / DD / YYYY")
        : null;

      const formattedWasteCollectionExpDate =
        statementDetails.wasteCollectionExpDate
          ? this.formatDate(statementDetails.wasteCollectionExpDate)
          : null;
      this.setState({
        id: id,
        region: statementDetails.region.name,
        RegionId: statementDetails.region.id,
        volume: statementDetails.garbageVolumeCategory.name,
        SelectedVolume: statementDetails.garbageVolumeCategory.id,
        user: statementDetails.user,
        firstName: statementDetails.user.firstName,
        lastName: statementDetails.user.lastName,
        phoneNumber: statementDetails.user.phoneNumber,
        email: statementDetails.user.email,
        address: statementDetails.address,
        description: statementDetails.description,
        status: statementDetails.status,
        price: statementDetails.price,
        dateCreated: this.formatDate(statementDetails.dateCreated),
        wasteImages: statementDetails.wasteImages,
        garbageWasteTypeNames: statementDetails.garbageWasteTypes.map(
          (x) => x.garbageWasteType.name
        ),
        GarbageWasteTypeIds: statementDetails.garbageWasteTypes.map(
          (x) => x.garbageWasteType.id
        ),
        notSeenCommentCount: statementDetails.notSeenCommentCount,
        longitude: statementDetails.longitude,
        latitude: statementDetails.latitude,
        wasteCollectionDate: formattedWasteCollectionDate,
        isLoadingDetails: false,
        originalState: {
          address: statementDetails.address,
          description: statementDetails.description,
          GarbageWasteTypeIds: statementDetails.garbageWasteTypes.map(
            (x) => x.garbageWasteType.id
          ),
          longitude: statementDetails.longitude,
          latitude: statementDetails.latitude,
        },
        wasteCollectionExpDate: formattedWasteCollectionExpDate,
      });
    } catch (error) {
      console.log("Error fetching statement details:", error);
      this.setState({ isLoadingDetails: false });
    }
  };

  formatDate(dateString) {
    const dateObj = new Date(dateString);
    const year = dateObj.getFullYear();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
    const day = dateObj.getDate().toString().padStart(2, "0");
    return `${day} / ${month} / ${year}`;
  }

  renderStatus = (status) => {
    const { phrase, color } = statusDetails[status] || {
      phrase: "Unknown",
      color: "black",
    };
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span style={{ fontWeight: "normal" }}>სტატუსი:&nbsp;</span>
        <span style={{ color: color }}>
          <b>{phrase}</b>
        </span>
      </div>
    );
  };

  handleRegionChange = (e) => {
    this.setState({ RegionId: e.target.value });
  };

  handleVolumeChange = (e) => {
    this.setState({ SelectedVolume: e.target.value });
  };

  toggleMapModal = () => {
    this.setState((prevState) => ({
      showMapModal: !prevState.showMapModal,
    }));
  };

  toggleMessageModal = async () => {
    const { id } = this.state;

    if (!this.state.showMessageModal) {
      try {
        const result = await setSeenStatus(id, this.props.token);
        if (result.success) {
          this.setState({ notSeenCommentCount: 0 });
        } else {
          console.error("Error setting seen status:", result.messages);
        }
      } catch (error) {
        console.error("Error setting seen status:", error);
      }
    }

    this.setState((prevState) => ({
      showMessageModal: !prevState.showMessageModal,
    }));
  };

  handleEditClick = () => {
    this.setState({ isEditing: true });
  };

  handleSaveClick = async () => {
    const {
      id,
      address,
      description,
      GarbageWasteTypeIds,
      longitude,
      latitude,
      SelectedVolume,
      RegionId,
    } = this.state;

    const updatedStatement = {
      StatementId: id,
      Address: address,
      Description: description,
      GarbageWasteTypeIds: [...new Set(GarbageWasteTypeIds)],
      Longitude: longitude,
      Latitude: latitude,
      Volume: SelectedVolume,
      RegionId: RegionId,
    };

    try {
      await updateStatementAPI(this.props.token, updatedStatement);
      this.setState({ isEditing: false, showSuccessModal: true });

      this.fetchStatementDetails();
    } catch (error) {
      console.error("Error updating statement", error);
    }
  };

  handleDiscardClick = () => {
    const { originalState } = this.state;
    this.setState({
      address: originalState.address,
      description: originalState.description,
      GarbageWasteTypeIds: originalState.GarbageWasteTypeIds,
      longitude: originalState.longitude,
      latitude: originalState.latitude,
      isEditing: false,
    });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      this.autoResizeTextarea(name);
    });
  };

  autoResizeTextarea = (name) => {
    const textarea = document.querySelector(`textarea[name="${name}"]`);
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  handleGarbageWasteTypeChange = (e) => {
    const { value, checked } = e.target;
    this.setState((prevState) => {
      const updatedGarbageWasteTypeIds = new Set(prevState.GarbageWasteTypeIds);
      if (checked) {
        updatedGarbageWasteTypeIds.add(value);
      } else {
        updatedGarbageWasteTypeIds.delete(value);
      }

      return { GarbageWasteTypeIds: Array.from(updatedGarbageWasteTypeIds) };
    });
  };

  handleShowUploadModal = () => {
    this.setState({ showUploadModal: true });
  };

  handleCloseUploadModal = async () => {
    this.setState({
      showUploadModal: false,
      selectedFiles: [],
      uploadAttemptedWithoutFiles: false,
      uploadSuccessful: false,
    });
    if (this.state.uploadSuccessful) {
      this.fetchStatementDetails();
    }
  };

  handleFileChange = (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      this.setState({
        selectedFiles: [...files],
        uploadAttemptedWithoutFiles: false,
        uploadSuccessful: false,
      });
    }
  };

  handleFileUpload = async () => {
    const { id, selectedFiles, status } = this.state;
    if (!id || selectedFiles.length === 0) {
      this.setState({ uploadAttemptedWithoutFiles: true });
      return;
    }

    const uploadData = {
      StatementId: id,
      AttachedFiles: selectedFiles || [],
    };

    try {
      let uploadResult;
      if (status === 5) {
        // If status is "გადახდილი" (5)
        uploadResult = await uploadWastePlacementFilesAPI(
          this.props.token,
          uploadData
        );
      } else {
        uploadResult = await uploadFilesAPI(this.props.token, uploadData);
      }

      console.log("Upload result", uploadResult);
      if (uploadResult.success) {
        console.log("ფოტო მასალა წარმატებით დაემატა!");
        this.setState({
          selectedFiles: [],
          uploadSuccessful: true,
        });
      } else {
        console.error("ფოტო მასალა ვერ დაემატა!", uploadResult.errors);
      }
    } catch (error) {
      console.error("ერორი ატვირთვის დროს!", error);
    }
  };

  handleGoBack = () => {
    this.props.router.navigate(`${homepage}/my-requests`);
  };

  handleCloseSuccessModal = () => {
    this.setState({ showSuccessModal: false });
  };

  render() {
    if (this.state.isLoadingDetails) {
      return <div>Loading...</div>;
    }

    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      dateCreated,
      address,
      description,
      price,
      wasteImages,
      status,
      isEditing,
      GarbageWasteTypeIds,
      garbageWasteTypeNames,
      showUploadModal,
      uploadAttemptedWithoutFiles,
      uploadSuccessful,
      showMessageModal,
      user,
      longitude,
      latitude,
      showMapModal,
      notSeenCommentCount,
      wasteCollectionDate,
      wasteCollectionExpDate,
      region,
      volume,
      RegionId,
      SelectedVolume,
    } = this.state;

    const canEdit = status === 0;
    const canUploadPictures =
      status !== 1 &&
      status !== 4 &&
      status !== 98 &&
      status !== 99 &&
      status !== 15; // You can upload unless status is 'დასრულებული'

    const modalTitle =
      status === 5
        ? "ატვირთეთ ნარჩენების გასატან ლოკაციაზე განთავსების დამადასტურებელი ფოტო" // Status "გადახდილი"
        : "დაამატეთ ფოტო მასალა";

    return (
      <div className="container" style={{ backgroundColor: "white" }}>
        <ListGroup style={{ fontSize: "13px", marginTop: "10px" }}>
          <ListGroup.Item className="d-flex justify-content-between align-items-center">
            <b className="mx-auto" style={{ fontSize: "20px" }}>
              სრული განცხადება
            </b>
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={this.handleGoBack}
              title="დაბრუნება"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="black"
                className="bi bi-arrow-left"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
                />
              </svg>
            </Button>
          </ListGroup.Item>

          <Row>
            <Col lg={6}>
              <ListGroup.Item>
                სახელი: <strong>{firstName}</strong>
              </ListGroup.Item>
              <ListGroup.Item>
                გვარი: <strong>{lastName}</strong>
              </ListGroup.Item>
              <ListGroup.Item>
                ელ-ფოსტა: <strong>{email}</strong>
              </ListGroup.Item>
              <ListGroup.Item className="list-group-item-custom">
                რაიონი:{" "}
                {isEditing ? (
                  <Form.Select
                    value={RegionId}
                    onChange={this.handleRegionChange}
                  >
                    <option value="" disabled hidden>
                      აირჩიეთ რაიონი
                    </option>
                    {tbilisiRegions.map((region) => (
                      <option key={region.id} value={region.id}>
                        {region.name}
                      </option>
                    ))}
                  </Form.Select>
                ) : (
                  <strong>{this.state.region}</strong>
                )}
              </ListGroup.Item>
            </Col>
            <Col md={6}>
              <ListGroup.Item>
                ტელეფონის ნომერი: <strong>{phoneNumber}</strong>
              </ListGroup.Item>
              <ListGroup.Item>
                შექმნის თარიღი: <strong>{dateCreated}</strong>
              </ListGroup.Item>
              <ListGroup.Item>{this.renderStatus(status)}</ListGroup.Item>
              <ListGroup.Item className="list-group-item-custom">
                მოცულობა:{" "}
                {isEditing ? (
                  <Form.Select
                    value={SelectedVolume}
                    onChange={this.handleVolumeChange}
                  >
                    <option value="" disabled hidden>
                      აირჩიეთ მოცულობა
                    </option>
                    {garbageVolumes.map((volume) => (
                      <option key={volume.id} value={volume.id}>
                        {volume.name}
                      </option>
                    ))}
                  </Form.Select>
                ) : (
                  <strong>{this.state.volume}</strong>
                )}
              </ListGroup.Item>
            </Col>
          </Row>

          {/* Render Description */}
          <ListGroup.Item className="list-group-item-custom">
            აღწერა:{" "}
            {isEditing ? (
              <Form.Control
                as="textarea"
                rows={1}
                name="description"
                value={description}
                onChange={this.handleChange}
                className="input-field"
              />
            ) : (
              <div className="text-area-container">
                <strong>{description}</strong>
              </div>
            )}
          </ListGroup.Item>

          {/* Render Address */}
          <ListGroup.Item className="list-group-item-custom">
            {" "}
            {longitude !== 0 && latitude !== 0 ? (
              <span
                style={{ color: "green", cursor: "pointer" }}
                onClick={this.toggleMapModal}
              >
                მისამართი:{" "}
              </span>
            ) : (
              "მისამართი: "
            )}
            {isEditing ? (
              <Form.Control
                as="textarea"
                rows={1}
                type="text"
                name="address"
                value={address}
                onChange={this.handleChange}
                className="input-field"
              />
            ) : (
              <div className="text-area-container">
                <strong>{address}</strong>
              </div>
            )}
          </ListGroup.Item>

          {/* Render Categories */}
          <ListGroup.Item>
            კატეგორიები:
            {isEditing ? (
              <Form.Group className="left-aligned-categories">
                {garbageWasteTypes.map((type) => (
                  <label key={type.id} className="checkbox-label">
                    <div>
                      <input
                        type="checkbox"
                        value={type.id}
                        onChange={this.handleGarbageWasteTypeChange}
                        checked={GarbageWasteTypeIds.includes(type.id)}
                        disabled={!isEditing}
                        className="hidden-checkbox"
                      />
                      <span
                        className={`checkbox-custom ${
                          GarbageWasteTypeIds.includes(type.id)
                            ? "checked"
                            : "unchecked"
                        }`}
                        style={{
                          backgroundImage: `url(${
                            GarbageWasteTypeIds.includes(type.id)
                              ? iconMap[type.id].checked
                              : iconMap[type.id].unchecked
                          })`,
                        }}
                      ></span>
                    </div>

                    <span style={{ fontSize: "15px", marginLeft: "5px" }}>
                      {type.name}
                      {GarbageWasteTypeIds.includes(type.id) && (
                        <span style={{ marginLeft: "5px", color: "green" }}>
                          ✔️
                        </span>
                      )}
                    </span>
                  </label>
                ))}
              </Form.Group>
            ) : (
              <ul className="left-aligned-categories">
                {GarbageWasteTypeIds.map((id, index) => (
                  <li
                    key={index}
                    className="category-item"
                    style={{ marginTop: "7px" }}
                  >
                    <div>
                      <span
                        className="checkbox-custom checked"
                        style={{
                          backgroundImage: `url(${iconMap[id].checked})`,
                        }}
                      ></span>
                    </div>
                    <b>
                      <span style={{ marginLeft: "7px" }}>
                        {garbageWasteTypes.find((type) => type.id === id).name}
                        <span
                          style={{ marginLeft: "5px", color: "green" }}
                        ></span>
                      </span>
                    </b>
                  </li>
                ))}
              </ul>
            )}
          </ListGroup.Item>

          {/* Render Waste Images */}
          <ListGroup.Item>
            ფოტომასალა:
            <Gallery images={wasteImages} token={this.props.token} />
          </ListGroup.Item>

          <ListGroup.Item>
            ფასი: <strong>{price ? `${price} ₾` : ""}</strong>
          </ListGroup.Item>

          <ListGroup.Item>
            სავარაუდო გატანის თარიღი: <strong>{wasteCollectionExpDate}</strong>
          </ListGroup.Item>

          {/* Display Buttons (Edit, Upload Pictures) */}
          <div
            className="container-fluid"
            style={{ marginTop: "5px", marginBottom: "100px", padding: "0px" }}
          >
            {canUploadPictures && !isEditing && (
              <Button
                variant="outline-success"
                onClick={this.handleShowUploadModal}
                className="add"
                title="ფოტოების დამატება"
                size="sm"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-images"
                >
                  <path d="M4.502 9a1.5.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                  <path d="M14.002 13a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2V5A2 2 0 0 1 2 3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v8a2 2 0 0 1-1.998 2M14 2H4a1 1 0 0 0-1 1h9.002a2 2 0 0 1 2 2v7A1 1 0 0 0 15 11V3a1 1 0 0 0-1-1M2.002 4a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1z" />
                </svg>
              </Button>
            )}
            {canEdit ? (
              isEditing ? (
                <>
                  <Button
                    size="sm"
                    variant="outline-success"
                    onClick={this.handleSaveClick}
                    className="editAccept"
                    title="შენახვა"
                  >
                    <AiOutlineCheck />
                  </Button>
                  <Button
                    size="sm"
                    variant="outline-danger"
                    onClick={this.handleDiscardClick}
                    className="editReject"
                    title="გაუქმება"
                  >
                    <AiOutlineClose />
                  </Button>
                </>
              ) : (
                <Button
                  variant="outline-secondary"
                  size="sm"
                  onClick={this.handleEditClick}
                  className="edit"
                  title="რედაქტირება"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-pencil-square"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                  </svg>
                </Button>
              )
            ) : null}

            {!isEditing && (
              <Button
                className="messages position-relative"
                variant={
                  notSeenCommentCount > 0 ? "outline-danger" : "outline-success"
                }
                size="sm"
                title="კომენტარები"
                data-target="#exampleModal"
                onClick={this.toggleMessageModal}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-chat-dots"
                  viewBox="0 0 16 16"
                >
                  <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
                  <path d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9 9 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.4 10.4 0 0 1-.524 2.318l-.003.011a11 11 0 0 1-.244.637c-.079.186.074.394.273.362a22 22 0 0 0 .693-.125m.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6-3.004 6-7 6a8 8 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a11 11 0 0 0 .398-2" />
                </svg>
                {notSeenCommentCount > 0 && (
                  <strong>
                    <span className="badge" style={{ marginLeft: "3px" }}>
                      {notSeenCommentCount}{" "}
                    </span>
                  </strong>
                )}
              </Button>
            )}
          </div>
        </ListGroup>

        <Modal
          show={showUploadModal}
          onHide={this.handleCloseUploadModal}
          onExited={this.handleCloseUploadModal}
          size="lg"
        >
          <Modal.Header>
            <Modal.Title style={{ fontSize: "17px" }}>{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!uploadSuccessful && (
              <input multiple type="file" onChange={this.handleFileChange} />
            )}
            {uploadAttemptedWithoutFiles && (
              <div style={{ color: "red", marginTop: "10px" }}>
                ფოტომასალა არ არის არჩეული!
              </div>
            )}
            {uploadSuccessful && (
              <div
                style={{
                  color: "green",
                  fontWeight: "bold",
                  marginTop: "10px",
                }}
              >
                ფოტო მასალა წარმატებით დაემატა!
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            {!uploadSuccessful && (
              <>
                <Button variant="success" onClick={this.handleFileUpload}>
                  დამატება
                </Button>
                <Button
                  variant="secondary"
                  onClick={this.handleCloseUploadModal}
                >
                  დახურვა
                </Button>
              </>
            )}
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showSuccessModal}
          onHide={this.handleCloseSuccessModal}
          style={{ marginTop: "150px" }}
        >
          <Modal.Body>
            <div
              style={{ color: "green", fontWeight: "bold", fontSize: "15px" }}
            >
              განცხადება წარმატებით განახლდა!
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseSuccessModal}>
              დახურვა
            </Button>
          </Modal.Footer>
        </Modal>

        {showMessageModal && (
          <MessagePage
            onClose={this.toggleMessageModal}
            token={this.props.token}
            userId={user.id}
            statementId={this.state.id}
          />
        )}

        <Modal size="xl" show={showMapModal}>
          <MapComponent
            longitude={longitude}
            latitude={latitude}
            onClose={this.toggleMapModal}
          />
        </Modal>
      </div>
    );
  }
}

function StatementInfo(props) {
  const params = useParams();
  return <StatementInfoClass {...props} params={params} />;
}

export default withAuth(withRouter(StatementInfo));
