import "./newRequest.css";
import React, { useState } from "react";
import { addStatementAPI, uploadFilesAPI } from "../../api/api";
import WithAuth from "../../auth/withAuth";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import {
  garbageWasteTypes,
  garbageVolumes,
  tbilisiRegions,
} from "../../constants";
import { useNavigate } from "react-router-dom";
import { homepage } from "../../constants";
import withRouter from "../../auth/withRouter";
import LocationPicker from "../maps/addressmap";
import IconsChecked1 from "../../icons/Icons-01.png";
import IconsUnchecked1 from "../../icons/Icons-02.png";
import IconsUnchecked2 from "../../icons/Icons-04.png";
import IconsChecked2 from "../../icons/Icons-03.png";
import IconsUnchecked3 from "../../icons/Icons-06.png";
import IconsChecked3 from "../../icons/Icons-05.png";
import IconsUnchecked5 from "../../icons/Icons-10.png";
import IconsChecked5 from "../../icons/Icons-09.png";

const iconMap = {
  "22268a71-9cad-474e-a789-5975d9649451": {
    unchecked: IconsUnchecked1,
    checked: IconsChecked1,
  },
  "d1da70b0-9d61-4822-a0b8-1895b14ec8bf": {
    unchecked: IconsUnchecked2,
    checked: IconsChecked2,
  },
  "b2e2dba1-bbb6-4ad1-bec0-f35df8d57ef3": {
    unchecked: IconsUnchecked3,
    checked: IconsChecked3,
  },
  "958f8e62-fc36-4cb6-99bc-e4e2628706cb": {
    unchecked: IconsUnchecked5,
    checked: IconsChecked5,
  },
};

function NewRequest(props) {
  const [Address, setAddress] = useState("");
  const [RegionId, setRegionId] = useState("");
  const [GarbageWasteTypeIds, setGarbageWasteTypeIds] = useState([]);
  const [UserId, setUserId] = useState("");
  const [Description, setDescription] = useState("");
  const [Images, setImages] = useState([]);
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const [showSuccessText, setShowSuccessText] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [addressError, setAddressError] = useState("");
  const [garbageWasteTypeError, setGarbageWasteTypeError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [SelectedVolume, setSelectedVolume] = useState("");
  const [volumeError, setVolumeError] = useState("");
  const [regionError, setRegionError] = useState("");
  const [price, setPrice] = useState("");
  const [checkboxOne, setCheckboxOne] = useState(false);
  const [checkboxTwo, setCheckboxTwo] = useState(false);
  const [addedStatementId, setAddedStatementId] = useState("");
  const [uploadAttemptedWithoutFiles, setUploadAttemptedWithoutFiles] =
    useState(false);
  const [location, setLocation] = useState({ latitude: 0, longitude: 0 });
  const [showMap, setShowMap] = useState(false);
  const [volumeDescription, setVolumeDescription] = useState("");

  const navigate = useNavigate();

  const handleFileChange = (e) => {
    setImages([...e.target.files]);
    if (e.target.files.length > 0) {
      setUploadAttemptedWithoutFiles(false);
    }
  };

  const volumeDescriptions = {
    // Ensure these IDs exactly match the `garbageVolumes` IDs
    "a6ffa086-3d22-40e3-b549-ebdc92f095fd": "9 ₾",
    "95e7694e-3292-42f9-90a8-cf9994fcebe1": "69 ₾",
    "f3b6855c-3d5c-42ec-b5a2-2a15dcfb7ea5": "149 ₾",
    "c0e1bc25-0929-4f8c-8f61-eaf71a254bd5": "399 ₾",
    "d8d8e44d-bfca-4164-8016-868335df836b": "ინდივიდუალური შეფასება",
  };

  const handleVolumeChange = (e) => {
    const value = e.target.value;
    console.log("Selected Volume ID: ", value); // Debugging log
    setSelectedVolume(value);

    // Set price based on selected volume
    const selectedVolume = garbageVolumes.find((volume) => volume.id === value);
    setPrice(selectedVolume ? selectedVolume.price : "");

    // Set the description for the selected volume
    setVolumeDescription(volumeDescriptions[value] || "");
  };
  const handleImageUpload = async () => {
    if (!addedStatementId || Images.length === 0) {
      setUploadAttemptedWithoutFiles(true);
      return;
    } else {
      setUploadAttemptedWithoutFiles(false);
    }

    const uploadData = {
      StatementId: addedStatementId,
      AttachedFiles: Images,
    };

    try {
      const uploadResult = await uploadFilesAPI(props.token, uploadData);
      console.log("Upload result", uploadResult);
      if (uploadResult.success) {
        console.log("ფოტო მასალა წარმატებით დაემატა!");
        setImages([]);
        setShowModal(true);
      } else {
        console.error("ფოტო მასალა ვერ დაემატა!", uploadResult.errors);
      }
    } catch (error) {
      console.error("ერორი ატვირთვის დროს!", error);
    }
  };

  const handleRegionChange = (e) => {
    const value = e.target.value;
    setRegionId(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setAddressError("");
    setGarbageWasteTypeError("");
    setDescriptionError("");
    setVolumeError("");
    setRegionError("");

    let isValid = true;

    if (!RegionId) {
      setRegionError(<strong>რაიონის არჩევა აუცილებელია!</strong>);
      isValid = false;
    }

    if (!Address.trim()) {
      setAddressError(<strong>მისამართის ველი ცარიელია!</strong>);
      isValid = false;
    }

    if (GarbageWasteTypeIds.length === 0) {
      setGarbageWasteTypeError(
        <strong> ნარჩენის ტიპ(ებ)ი არ არის მონიშნული!</strong>
      );
      isValid = false;
    }

    if (!Description.trim()) {
      setDescriptionError(<strong>აღწერის ველი ცარიელია!</strong>);
      isValid = false;
    }

    if (!SelectedVolume) {
      setVolumeError(<strong>მოცულობის არჩევა აუცილებელია!</strong>);
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    const formData = {
      Address,
      GarbageWasteTypeIds,
      Description,
      Latitude: location.latitude,
      Longitude: location.longitude,
      UserId,
      GarbageVolumeCategoryId: SelectedVolume,
      RegionId, // Added RegionId here
    };

    try {
      let addStatement = await addStatementAPI(props.token, formData);

      if (addStatement.isSuccess) {
        setAddedStatementId(addStatement.messages[1]);
      }
      setShowSuccessText(true);
      setShowAdvancedOptions(
        (prevShowAdvancedOptions) => !prevShowAdvancedOptions
      );
    } catch (error) {
      console.error("განცხადება ვერ დაემატა!", error);
    }
  };

  const handleGarbageWasteTypeChange = (e) => {
    const { value, checked } = e.target;
    const updatedGarbageWasteTypeIds = checked
      ? [...GarbageWasteTypeIds, value]
      : GarbageWasteTypeIds.filter((id) => id !== value);
    setGarbageWasteTypeIds(updatedGarbageWasteTypeIds);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    navigate(`${homepage}/my-requests`);
  };

  const isFormValid = () => {
    return (
      Address.trim() &&
      Description.trim() &&
      GarbageWasteTypeIds.length > 0 &&
      SelectedVolume &&
      RegionId &&
      checkboxOne &&
      checkboxTwo
    );
  };

  return (
    <>
      <Form className="new-request-block">
        {/* Region Selector */}
        <Form.Group className="mb-3">
          <Form.Select
            aria-label="Select region"
            style={{ fontSize: "16px" }}
            defaultValue=""
            value={RegionId}
            onChange={handleRegionChange}
            disabled={showAdvancedOptions}
          >
            <option value="" disabled hidden>
              აირჩიეთ რაიონი
            </option>
            {tbilisiRegions.map((region) => (
              <option key={region.id} value={region.id}>
                {region.name}
              </option>
            ))}
          </Form.Select>
          {regionError && (
            <Form.Text className="text-danger error-text">
              {regionError}
            </Form.Text>
          )}
        </Form.Group>

        {/* Address input */}
        <Form.Group className="mb-3">
          <Form.Label>მისამართი:</Form.Label>
          <Form.Control
            type="text"
            as="textarea"
            rows={2}
            value={Address}
            onChange={(e) => setAddress(e.target.value)}
            disabled={showAdvancedOptions}
            className={`newRequestInputValue ${addressError && "is-invalid"}`}
          />
          {addressError && (
            <Form.Text className="text-danger error-text">
              {addressError}
            </Form.Text>
          )}
          {!showSuccessText && (
            <div
              style={{
                marginTop: "25px",
                cursor: "pointer",
                color: "green",
                textDecoration: "none",
              }}
              onClick={() => setShowMap(!showMap)}
            >
              მონიშნეთ ლოკაცია
            </div>
          )}
          {showMap && (
            <Form.Group className="mb-3">
              <LocationPicker location={location} setLocation={setLocation} />
            </Form.Group>
          )}
        </Form.Group>

        {/* Description input */}
        <Form.Group className="mb-3" style={{ marginTop: "4px" }}>
          <Form.Label>დეტალური აღწერა:</Form.Label>
          <Form.Control
            as="textarea"
            type="text"
            rows={3}
            value={Description}
            onChange={(e) => setDescription(e.target.value)}
            disabled={showAdvancedOptions}
            className={`newRequestInputValue ${
              descriptionError && "is-invalid"
            }`}
          />
          {descriptionError && (
            <Form.Text className="text-danger error-text">
              {descriptionError}
            </Form.Text>
          )}
        </Form.Group>

        {/* Waste type checkboxes */}
        <Form.Group className="mb-3">
          <Form.Label style={{ marginBottom: "5px", fontSize: "17px" }}>
            მონიშნეთ ნარჩენის ტიპ(ებ)ი:
          </Form.Label>
          {garbageWasteTypes.map((type) => (
            <label key={type.id} className="checkbox-label">
              <div>
                <input
                  type="checkbox"
                  value={type.id}
                  onChange={handleGarbageWasteTypeChange}
                  checked={GarbageWasteTypeIds.includes(type.id)}
                  disabled={showAdvancedOptions}
                  className="hidden-checkbox"
                />
                <span
                  className={`checkbox-custom ${
                    GarbageWasteTypeIds.includes(type.id)
                      ? "checked"
                      : "unchecked"
                  }`}
                  style={{
                    backgroundImage: `url(${
                      GarbageWasteTypeIds.includes(type.id)
                        ? iconMap[type.id]?.checked
                        : iconMap[type.id]?.unchecked
                    })`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    display: "inline-block",
                    width: "30px",
                    height: "30px",
                  }}
                ></span>
              </div>
              <span style={{ fontSize: "15px", marginLeft: "5px" }}>
                {type.name}
                {GarbageWasteTypeIds.includes(type.id) && (
                  <span style={{ marginLeft: "5px", color: "green" }}>✔️</span>
                )}
              </span>
            </label>
          ))}
        </Form.Group>

        {/* Volume selector */}
        <Form.Group className="mb-3" style={{ marginTop: "-5px" }}>
          <Form.Select
            aria-label="Select waste volume"
            style={{ fontSize: "16px" }}
            defaultValue=""
            value={SelectedVolume}
            onChange={handleVolumeChange}
            disabled={showAdvancedOptions}
          >
            <option value="" disabled hidden>
              აირჩიეთ მოცულობა
            </option>
            {garbageVolumes.map((volume) => (
              <option key={volume.id} value={volume.id}>
                {volume.name}
              </option>
            ))}
          </Form.Select>
          {volumeError && (
            <Form.Text className="text-danger error-text">
              {volumeError}
            </Form.Text>
          )}
        </Form.Group>

        {/* Price */}
        <Form.Group>
          <div
            style={{ display: "flex", alignItems: "center", fontSize: "15px" }}
          >
            <p style={{ margin: "0" }}>
              <strong>სავარაუდო ფასი: </strong>
              <strong>{price}</strong>
            </p>
            {volumeDescription && (
              <strong style={{ marginLeft: "15px" }}>
                {volumeDescription}
              </strong>
            )}
          </div>
        </Form.Group>

        {/* Checkbox responsibilities */}
        <Form.Group style={{ marginTop: "10px", fontSize: "15px" }}>
          <span>
            <strong>
              გაითვალისწინეთ, რომ ნარჩენების გატანა არ ხდება საცხოვრებელი
              ბინიდან / სართულიდან.
            </strong>
          </span>
        </Form.Group>
        <Form.Group style={{ marginTop: "10px", fontSize: "15px" }}>
          <span>
            <strong>
              გაითვალისწინეთ, რომ ნარჩენების განთავსება არ არის ნებადართული
              ნაგვის ურნასთან.
            </strong>
          </span>
        </Form.Group>
        <Form.Group
          style={{ fontSize: "15px", marginTop: "10px" }}
          className="request-checkbox"
        >
          <Form.Check
            style={{ marginBottom: "3px" }}
            type="checkbox"
            label="ვიღებ პასუხისმგებლობას, განვათავსო ნარჩენები გასატან ლოკაციაზე. (სადარბოზთან, შლაგბაუმთან და ა.შ.)"
            checked={checkboxOne}
            onChange={(e) => setCheckboxOne(e.target.checked)}
            disabled={showAdvancedOptions}
          />
          <Form.Check
            type="checkbox"
            label="ვიღებ პასუხისმგებლობას, ავტვირთო ნარჩენების გასატან ლოკაციაზე განთავსების დამადასტურებელი ფოტომასალა."
            checked={checkboxTwo}
            onChange={(e) => setCheckboxTwo(e.target.checked)}
            disabled={showAdvancedOptions}
          />
        </Form.Group>

        <Form.Group style={{ marginTop: "10px", fontSize: "15px" }}>
          <span>
            <strong>
              სავარაუდო გატანის თარიღი: ნარჩენების გასატან ლოკაციაზე განთავსების
              დამადასტურებელი ფოტოს ატვირთვიდან 72 საათი.
            </strong>
          </span>
        </Form.Group>

        {garbageWasteTypeError && (
          <Form.Text className="text-danger error-text">
            {garbageWasteTypeError}
          </Form.Text>
        )}

        {/* Success message */}
        {showSuccessText && (
          <Form.Group
            style={{ color: "green", fontWeight: "bold", fontSize: "15px" }}
            className="mb-3"
          >
            <Form.Label>ინფორმაცია წარმატებით დაემატა!</Form.Label>
          </Form.Group>
        )}

        {/* Continue button */}
        <Form.Group className="mb-3">
          {!showAdvancedOptions && (
            <Button
              variant="outline-success"
              onClick={handleSubmit}
              disabled={!isFormValid()}
            >
              გაგრძელება
            </Button>
          )}
        </Form.Group>

        {/* Advanced options and file upload */}
        {showAdvancedOptions && addedStatementId && (
          <>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label className="newRequestInputValue">
                ატვირთეთ ნარჩენების ამსახველი ფოტო მასალა:
              </Form.Label>
              <Form.Control
                type="file"
                accept=".jpg, .jpeg, .png, .pdf, .tiff"
                className="newRequestInputValue"
                multiple
                onChange={handleFileChange}
              />
            </Form.Group>
            {uploadAttemptedWithoutFiles && (
              <div style={{ color: "red", fontWeight: "bold" }}>
                ფოტომასალა არ არის ატვირთული! გთხოვთ, აირჩიოთ ფაილები
                ასატვირთად.
              </div>
            )}
            <Form.Group className="mb-3">
              <Button variant="outline-success" onClick={handleImageUpload}>
                განცხადების დამატება
              </Button>
            </Form.Group>
            <Modal show={showModal} style={{ marginTop: "150px" }}>
              <Modal.Body style={{ color: "green", fontSize: "15px" }}>
                <strong>განცხადება წარმატებით დაემატა!</strong>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                  დახურვა
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </Form>
    </>
  );
}

export default WithAuth(withRouter(NewRequest));
