import {
  AUTHORIZATION_LOAD_BEGIN,
  AUTHORIZATION_LOAD_SUCCESS,
  AUTHORIZATION_LOAD_ERROR,
} from '../actions/ActionTypes'

const initialState = {
  login: null,
  loader: false,
  error: null,
}

const authorizationReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case AUTHORIZATION_LOAD_BEGIN:
      return { loader: true, error: null }
    case AUTHORIZATION_LOAD_SUCCESS:
      return {
        login: payload.result,
        loader: false,
        error: null,
      }
    case AUTHORIZATION_LOAD_ERROR:
      return { error: payload, loader: false }
    default:
      return state
  }
}

export default authorizationReducer
