import React from "react";
import "./footer.css";
import withAuth from "../../auth/withAuth";

const Footer = () => {
  return (
    <footer className="footer bg-dark text-white text-center py-3 mt-auto">
      <div
        className="container d-flex align-items-center justify-content-center"
        style={{ height: "5px" }}
      >
        <p className="mb-0" style={{ fontSize: "14px" }}>
          საიტი მუშაობს სატესტო რეჟიმში!
        </p>
      </div>
    </footer>
  );
};

export default Footer;
