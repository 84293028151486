import React, { useState, useEffect } from "react";
import {
  getStatementById,
  updateStatementAPI,
  uploadFilesAPI,
  PaymentFunction,
} from "../../api/api";
import ListGroup from "react-bootstrap/ListGroup";
import { Button, Form, Modal, Row, Col } from "react-bootstrap";
import { useNavigate, useParams, Navigate } from "react-router-dom"; // Import hooks from react-router-dom
import withAuth from "../../auth/withAuth";
import MessagePage from "../messages/MessagePage";
import { homepage } from "../../constants";
import MapComponent from "../maps/mapcomponent";
import IconsChecked1 from "../../icons/Icons-01.png";
import IconsUnchecked1 from "../../icons/Icons-02.png";
import IconsUnchecked2 from "../../icons/Icons-04.png";
import IconsChecked2 from "../../icons/Icons-03.png";
import IconsUnchecked3 from "../../icons/Icons-06.png";
import IconsChecked3 from "../../icons/Icons-05.png";
import IconsUnchecked4 from "../../icons/Icons-08.png";
import IconsChecked4 from "../../icons/Icons-07.png";
import IconsUnchecked5 from "../../icons/Icons-10.png";
import IconsChecked5 from "../../icons/Icons-09.png";

import "./PaymentPage.css";
import withRouter from "../../auth/withRouter";

const statusDetails = {
  0: { phrase: "განხილვაში", color: "rgb(205, 123, 0)" },
  1: { phrase: "დასრულებული", color: "green" },
  5: { phrase: "გადახდილი", color: "green" },
  4: { phrase: "განთავსებული", color: "green" },
  10: { phrase: "დასადასტურებელი", color: "green" },
  15: { phrase: "გადასახდელი", color: "rgb(255,128,0)" },
  98: { phrase: "გაუქმებული", color: "rgb(205,0,0)" },
  99: { phrase: "უარყოფილი", color: "rgb(205,0,0)" },
};

const garbageWasteTypes = [
  {
    id: "22268a71-9cad-474e-a789-5975d9649451",
    name: `სამშენებლო მასალა ტომრებით: ბეტონი, გაჯი, მეტლახი და ა.შ.`,
  },
  {
    id: "d1da70b0-9d61-4822-a0b8-1895b14ec8bf",
    name: "სამშენებლო ხის მასალა: კარი, ფანჯარა და ა.შ.",
  },
  {
    id: "b2e2dba1-bbb6-4ad1-bec0-f35df8d57ef3",
    name: "საყოფაცხოვრებო ავეჯი: კარადა, საწოლი და ა.შ.",
  },
  {
    id: "9845a110-5e81-49c2-8b99-ffc9b78fc1f1",
    name: "საბურავები",
  },
  {
    id: "958f8e62-fc36-4cb6-99bc-e4e2628706cb",
    name: "ხის ტოტები / დასუფთავებული კერძო ბაღის ნარჩენები",
  },
];

const iconMap = {
  "22268a71-9cad-474e-a789-5975d9649451": {
    unchecked: IconsUnchecked1,
    checked: IconsChecked1,
  },
  "d1da70b0-9d61-4822-a0b8-1895b14ec8bf": {
    unchecked: IconsUnchecked2,
    checked: IconsChecked2,
  },
  "b2e2dba1-bbb6-4ad1-bec0-f35df8d57ef3": {
    unchecked: IconsUnchecked3,
    checked: IconsChecked3,
  },
  "9845a110-5e81-49c2-8b99-ffc9b78fc1f1": {
    unchecked: IconsUnchecked4,
    checked: IconsChecked4,
  },
  "958f8e62-fc36-4cb6-99bc-e4e2628706cb": {
    unchecked: IconsUnchecked5,
    checked: IconsChecked5,
  },
};

const PaymentPage = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [state, setState] = useState({
    user: {
      id: "",
    },
    token: "",
    firstName: "",
    lastName: "",
    address: "",
    description: "",
    email: "",
    phoneNumber: "",
    id: "",
    wasteImages: [],
    dateCreated: "",
    status: "",
    price: null,
    statementId: "",
    isLoadingDetails: false,
    garbageWasteTypeNames: [],
    GarbageWasteTypeIds: [],
    isEditing: false,
    showUploadModal: false,
    selectedFiles: [],
    uploadAttemptedWithoutFiles: false,
    uploadSuccessful: false,
    showMessageModal: false,
    showSuccessModal: false,
    showRejectionModal: false,
    originalState: {},
    showMapModal: false,
    paymentSuccessful: false,
    redirectToSuccess: false,
    redirectToError: false,
  });

  useEffect(() => {
    fetchStatementDetails();
  }, []);

  const fetchStatementDetails = async () => {
    setState({ ...state, isLoadingDetails: true });
    try {
      let statementDetails = await getStatementById(props.token, id);
      setState({
        ...state,
        id: id,
        user: statementDetails.user,
        firstName: statementDetails.user.firstName,
        lastName: statementDetails.user.lastName,
        phoneNumber: statementDetails.user.phoneNumber,
        email: statementDetails.user.email,
        address: statementDetails.address,
        description: statementDetails.description,
        status: statementDetails.status,
        price: statementDetails.price,
        dateCreated: formatDate(statementDetails.dateCreated),
        wasteImages: statementDetails.wasteImages,
        garbageWasteTypeNames: statementDetails.garbageWasteTypes.map(
          (x) => x.garbageWasteType.name
        ),
        GarbageWasteTypeIds: statementDetails.garbageWasteTypes.map(
          (x) => x.garbageWasteType.id
        ),
        longitude: statementDetails.longitude,
        latitude: statementDetails.latitude,
        isLoadingDetails: false,
        originalState: {
          address: statementDetails.address,
          description: statementDetails.description,
          GarbageWasteTypeIds: statementDetails.garbageWasteTypes.map(
            (x) => x.garbageWasteType.id
          ),
          longitude: statementDetails.longitude,
          latitude: statementDetails.latitude,
        },
        region: statementDetails.region.name,
        volume: statementDetails.garbageVolumeCategory.name,
      });
    } catch (error) {
      console.log("Error fetching statement details:", error);
      setState({ ...state, isLoadingDetails: false });
    }
  };

  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    const year = dateObj.getFullYear();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
    const day = dateObj.getDate().toString().padStart(2, "0");
    return `${month} / ${day} / ${year}`;
  };

  const renderStatus = (status) => {
    const { phrase, color } = statusDetails[status] || {
      phrase: "Unknown",
      color: "black",
    };
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span style={{ fontWeight: "normal" }}>სტატუსი:&nbsp;</span>
        <span style={{ color: color }}>{phrase}</span>
      </div>
    );
  };

  const toggleMapModal = () => {
    setState((prevState) => ({
      ...prevState,
      showMapModal: !prevState.showMapModal,
    }));
  };

  const toggleMessageModal = async () => {
    setState((prevState) => ({
      ...prevState,
      showMessageModal: !prevState.showMessageModal,
    }));
  };

  const handleEditClick = () => {
    setState({ ...state, isEditing: true });
  };

  const handleSaveClick = async () => {
    const {
      id,
      address,
      description,
      GarbageWasteTypeIds,
      longitude,
      latitude,
    } = state;

    const updatedStatement = {
      StatementId: id,
      Address: address,
      Description: description,
      GarbageWasteTypeIds: [...new Set(GarbageWasteTypeIds)],
      Longitude: longitude,
      Latitude: latitude,
    };

    try {
      await updateStatementAPI(props.token, updatedStatement);
      setState({ ...state, isEditing: false, showSuccessModal: true });
      fetchStatementDetails();
    } catch (error) {
      console.error("Error updating statement", error);
    }
  };

  const handleDiscardClick = () => {
    const { originalState } = state;
    setState({
      ...state,
      address: originalState.address,
      description: originalState.description,
      GarbageWasteTypeIds: originalState.GarbageWasteTypeIds,
      longitude: originalState.longitude,
      latitude: originalState.latitude,
      isEditing: false,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value }, () => {
      autoResizeTextarea(name);
    });
  };

  const autoResizeTextarea = (name) => {
    const textarea = document.querySelector(`textarea[name="${name}"]`);
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const handleGarbageWasteTypeChange = (e) => {
    const { value, checked } = e.target;
    setState((prevState) => {
      const updatedGarbageWasteTypeIds = new Set(prevState.GarbageWasteTypeIds);
      if (checked) {
        updatedGarbageWasteTypeIds.add(value);
      } else {
        updatedGarbageWasteTypeIds.delete(value);
      }

      return {
        ...prevState,
        GarbageWasteTypeIds: Array.from(updatedGarbageWasteTypeIds),
      };
    });
  };

  const handleShowUploadModal = () => {
    setState({ ...state, showUploadModal: true });
  };

  const handleCloseUploadModal = async () => {
    setState({
      ...state,
      showUploadModal: false,
      selectedFiles: [],
      uploadAttemptedWithoutFiles: false,
      uploadSuccessful: false,
    });
    if (state.uploadSuccessful) {
      fetchStatementDetails();
    }
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      setState({
        ...state,
        selectedFiles: [...files],
        uploadAttemptedWithoutFiles: false,
        uploadSuccessful: false,
      });
    }
  };

  const handleFileUpload = async () => {
    const { id, selectedFiles } = state;
    if (!id || selectedFiles.length === 0) {
      setState({ ...state, uploadAttemptedWithoutFiles: true });
      return;
    }

    const uploadData = {
      StatementId: id,
      AttachedFiles: selectedFiles || [],
    };

    try {
      const uploadResult = await uploadFilesAPI(props.token, uploadData);
      console.log("Upload result", uploadResult);
      if (uploadResult.success) {
        console.log("ფოტო მასალა წარმატებით დაემატა!");
        setState({
          ...state,
          selectedFiles: [],
          uploadSuccessful: true,
        });
      } else {
        console.error("ფოტო მასალა ვერ დაემატა!", uploadResult.errors);
      }
    } catch (error) {
      console.error("ერორი ატვირთვის დროს!", error);
    }
  };

  const handleGoBack = () => {
    navigate(`${homepage}/my-requests`);
  };

  const handlePaymentClick = async () => {
    const { id, price } = state;
    try {
      const response = await PaymentFunction(props.token, {
        statementId: id,
        amount: price,
      });

      if (response.isSuccess) {
        console.log(response);
        setState({ ...state, redirectToSuccess: true });
        window.location.href = response.messages[1];
      } else {
        setState({ ...state, redirectToError: true });
        console.error("Payment failed:", response.messages);
      }
    } catch (error) {
      setState({ ...state, redirectToError: true });
      if (error.message.includes("401")) {
        console.error("User is not authorized.");
      } else {
        console.error("Error:", error.message);
      }
    }
  };

  const handlePaymentCloseSuccessModal = () => {
    setState({ ...state, showSuccessModal: false }, () => {
      window.location.reload();
    });
  };

  const handlePaymentCloseRejectionModal = () => {
    setState({ ...state, showRejectionModal: false }, () => {
      window.location.reload();
    });
  };

  if (state.isLoadingDetails) {
    return <div>Loading...</div>;
  }

  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    dateCreated,
    address,
    description,
    price,
    wasteImages,
    status,
    isEditing,
    GarbageWasteTypeIds,
    garbageWasteTypeNames,
    showUploadModal,
    uploadAttemptedWithoutFiles,
    uploadSuccessful,
    showMessageModal,
    user,
    longitude,
    latitude,
    showMapModal,
    paymentSuccessful,
    showSuccessModal,
    showRejectionModal,
    region,
    volume,
  } = state;

  const canEdit = status === 0;

  return (
    <div className="container" style={{ backgroundColor: "#f2f2f2" }}>
      <ListGroup style={{ fontSize: "13px", marginTop: "10px" }}>
        <ListGroup.Item className="d-flex justify-content-between align-items-center">
          <b className="mx-auto" style={{ fontSize: "20px" }}>
            გადახდის გვერდი
          </b>
          <Button
            size="sm"
            variant="outline-secondary"
            onClick={handleGoBack}
            title="დაბრუნება"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="black"
              className="bi bi-arrow-left"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
              />
            </svg>
          </Button>
        </ListGroup.Item>
        <Row>
          <Col lg={6}>
            <ListGroup.Item>
              სახელი: <strong>{firstName}</strong>
            </ListGroup.Item>
            <ListGroup.Item>
              გვარი: <strong>{lastName}</strong>
            </ListGroup.Item>
            <ListGroup.Item>
              ელ-ფოსტა: <strong>{email}</strong>
            </ListGroup.Item>
            <ListGroup.Item>
              რაიონი: <strong>{region}</strong>
            </ListGroup.Item>
          </Col>
          <Col md={6}>
            <ListGroup.Item>
              ტელეფონის ნომერი: <strong>{phoneNumber}</strong>
            </ListGroup.Item>
            <ListGroup.Item>
              შექმნის თარიღი: <strong>{dateCreated}</strong>
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>{renderStatus(status)}</strong>
            </ListGroup.Item>
            <ListGroup.Item>
              მოცულობა: <strong>{volume}</strong>
            </ListGroup.Item>
          </Col>
        </Row>
        <ListGroup.Item>
          {longitude !== 0 && latitude !== 0 ? (
            <span
              style={{ color: "green", cursor: "pointer" }}
              onClick={toggleMapModal}
            >
              მისამართი:{" "}
            </span>
          ) : (
            "მისამართი: "
          )}
          {isEditing ? (
            <Form.Control
              as="textarea"
              rows={1}
              type="text"
              name="address"
              value={address}
              onChange={handleChange}
              className="input-field"
            />
          ) : (
            <strong>{address}</strong>
          )}
        </ListGroup.Item>
        <ListGroup.Item>
          აღწერა:{" "}
          {isEditing ? (
            <Form.Control
              as="textarea"
              rows={1}
              name="description"
              value={description}
              onChange={handleChange}
              className="input-field"
            />
          ) : (
            <strong>{description}</strong>
          )}
        </ListGroup.Item>
        <ListGroup.Item>
          კატეგორიები:
          {isEditing ? (
            <Form.Group className="left-aligned-categories">
              {garbageWasteTypes.map((type) => (
                <label key={type.id} className="checkbox-label">
                  <div>
                    <input
                      type="checkbox"
                      value={type.id}
                      onChange={handleGarbageWasteTypeChange}
                      checked={GarbageWasteTypeIds.includes(type.id)}
                      disabled={!isEditing}
                      className="hidden-checkbox"
                    />
                    <span
                      className={`checkbox-custom ${
                        GarbageWasteTypeIds.includes(type.id)
                          ? "checked"
                          : "unchecked"
                      }`}
                      style={{
                        backgroundImage: `url(${
                          GarbageWasteTypeIds.includes(type.id)
                            ? iconMap[type.id].checked
                            : iconMap[type.id].unchecked
                        })`,
                      }}
                    ></span>
                  </div>

                  <span style={{ fontSize: "15px", marginLeft: "5px" }}>
                    {type.name}
                    {GarbageWasteTypeIds.includes(type.id) && (
                      <span style={{ marginLeft: "5px", color: "green" }}>
                        ✔️
                      </span>
                    )}
                  </span>
                </label>
              ))}
            </Form.Group>
          ) : (
            <ul className="left-aligned-categories">
              {GarbageWasteTypeIds.map((id, index) => (
                <li key={index} className="category-item">
                  <div>
                    <span
                      className="checkbox-custom checked"
                      style={{
                        backgroundImage: `url(${iconMap[id].checked})`,
                      }}
                    ></span>
                  </div>
                  <b>
                    <span style={{ marginLeft: "7px" }}>
                      {garbageWasteTypes.find((type) => type.id === id).name}
                      <span
                        style={{ marginLeft: "5px", color: "green" }}
                      ></span>
                    </span>
                  </b>
                </li>
              ))}
            </ul>
          )}
        </ListGroup.Item>

        <ListGroup.Item>
          ფასი: <strong>{price ? `${price} ₾` : ""}</strong>
        </ListGroup.Item>

        {canEdit ? (
          isEditing ? (
            <>
              <Button
                size="sm"
                variant="outline-success"
                onClick={handleSaveClick}
                className="editAccept"
                title="შენახვა"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-check2"
                  viewBox="0 0 16 16"
                >
                  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0" />
                </svg>
              </Button>
              <Button
                size="sm"
                variant="outline-danger"
                onClick={handleDiscardClick}
                className="editReject"
                title="გაუქმება"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-x"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                </svg>
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="outline-secondary"
                size="sm"
                onClick={handleEditClick}
                className="edit"
                title="რედაქტირება"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-pencil-square"
                  viewBox="0 0 16 16"
                >
                  <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                </svg>
              </Button>
              <Button
                variant="outline-success"
                onClick={handleShowUploadModal}
                className="add"
                title="ფოტოების დამატება"
                size="sm"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-images"
                >
                  <path d="M4.502 9a1.5.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                  <path d="M14.002 13a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2V5A2 2 0 0 1 2 3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v8a2 2 0 0 1-1.998 2M14 2H4a1 1 0 0 0-1 1h9.002a2 2 0 0 1 2 2v7A1 1 0 0 0 15 11V3a1 1 0 0 0-1-1M2.002 4a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1z" />
                </svg>
              </Button>
            </>
          )
        ) : null}
        <div
          className="container-fluid"
          style={{
            marginTop: "5px",
            marginBottom: "100px",
            padding: "0px",
          }}
        >
          {!isEditing && status !== 5 && (
            <Button variant="success" onClick={handlePaymentClick}>
              გადახდა
            </Button>
          )}
        </div>
      </ListGroup>

      <Modal
        show={showUploadModal}
        onHide={handleCloseUploadModal}
        onExited={handleCloseUploadModal}
      >
        <Modal.Header>
          <Modal.Title>დაამატეთ ფოტო მასალა</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!uploadSuccessful && (
            <input multiple type="file" onChange={handleFileChange} />
          )}
          {uploadAttemptedWithoutFiles && (
            <div style={{ color: "red", marginTop: "10px" }}>
              ფოტომასალა არ არის არჩეული!
            </div>
          )}
          {uploadSuccessful && (
            <div
              style={{
                color: "green",
                fontWeight: "bold",
                marginTop: "10px",
              }}
            >
              ფოტო მასალა წარმატებით დაემატა!
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {!uploadSuccessful && (
            <>
              <Button variant="success" onClick={handleFileUpload}>
                დამატება
              </Button>
              <Button variant="secondary" onClick={handleCloseUploadModal}>
                დახურვა
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>

      <Modal
        show={showSuccessModal}
        onHide={handlePaymentCloseSuccessModal}
        backdrop="true"
        keyboard="true"
        style={{ marginTop: "150px" }}
      >
        <Modal.Body>
          <div style={{ color: "green", fontWeight: "bold", fontSize: "15px" }}>
            გადახდა წარმატებით შესრულდა!
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handlePaymentCloseSuccessModal}>
            დახურვა
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showRejectionModal}
        onHide={handlePaymentCloseRejectionModal}
        backdrop="true"
        keyboard="true"
        style={{ marginTop: "150px" }}
      >
        <Modal.Body>
          <div style={{ color: "red", fontWeight: "bold", fontSize: "15px" }}>
            გადახდა ვერ შესრულდა!
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handlePaymentCloseRejectionModal}
          >
            დახურვა
          </Button>
        </Modal.Footer>
      </Modal>

      {showMessageModal && (
        <MessagePage
          onClose={toggleMessageModal}
          token={props.token}
          userId={user.id}
          statementId={state.id}
        />
      )}

      <Modal size="xl" show={showMapModal} onHide={toggleMapModal}>
        <MapComponent
          longitude={longitude}
          latitude={latitude}
          onChange={handleChange}
        />
      </Modal>
    </div>
  );
};

export default withAuth(withRouter(PaymentPage));
