//test,local
export const homepage = "/ui";

//real
//export  const homepage = ''

export const operationStatuses = [
  {
    id: "41ab3791-a327-4117-bd8a-499a4b20d552",
    name: "დანიშვნა",
  },
  {
    id: "137909e6-4155-4d6a-b762-623bd119491c",
    name: "შეწყვეტა",
  },
  {
    id: "c9d599e2-ffd0-4004-96fa-8f5f743709e0",
    name: "შეჩერება",
  },
  {
    id: "ea2d1bd7-79f2-4466-ab97-ab43f00f6174",
    name: "ცვლილება",
  },
  {
    id: "9404c89f-140f-4d5c-b01a-b3335dba241b",
    name: "აღდგენა",
  },
];

export const pensionTypes = [
  {
    id: "5a5bb5d8-ef44-43ed-40cc-08d8ea07976f",
    name: "სახელმწიფო პენსია",
  },
  {
    id: "69a58367-2798-4082-80e1-19a1385e9917",
    name: "ომის მონაწილეთა დახმარება",
  },
  {
    id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    name: "საყოფაცხოვრებო სუბსიდია",
  },
  {
    id: "07e9955f-ce79-4b22-b637-97c1e19612e7",
    name: "მაღალმთიანის დანამატი",
  },
];

export const tbilisiRegions = [
  {
    id: "58cdbdcf-6222-4586-b216-1d0b8594f322",
    name: "მთაწმინდის რაიონი",
  },
  {
    id: "12e2395e-6b6e-42da-b8e9-01e3f0665d4f",
    name: "ვაკის რაიონი",
  },
  {
    id: "3a619498-58dd-4233-bb27-63ee81f58597",
    name: "საბურთალოს რაიონი",
  },
  {
    id: "e9128945-c3dc-4ef1-ae4a-65206768b87d",
    name: "კრწანისის რაიონი",
  },
  {
    id: "2789ee41-5779-4d2e-bf5e-1638588ab065",
    name: "ისნის რაიონი",
  },
  {
    id: "7985ac97-42d0-4c64-8ad4-ea75583654f8",
    name: "სამგორის რაიონი",
  },
  {
    id: "c1325616-c165-4802-935c-1d47027fc2a7",
    name: "ჩუღურეთის რაიონი",
  },
  {
    id: "a0bdc6cf-9fba-4b37-b98d-390d5ddbde3e",
    name: "დიდუბის რაიონი",
  },
  {
    id: "39d159ea-cf0a-4386-bf79-4574d4de30c3",
    name: "ნაძალადევის რაიონი",
  },
  {
    id: "349f6bc3-7991-46f6-88de-1449d233d49f",
    name: "გლდანის რაიონი",
  },
];

export const garbageVolumes = [
  {
    id: "a6ffa086-3d22-40e3-b549-ebdc92f095fd",
    name: "<1 მ³",
  },
  {
    id: "95e7694e-3292-42f9-90a8-cf9994fcebe1",
    name: "1 - 3 მ³",
  },
  {
    id: "f3b6855c-3d5c-42ec-b5a2-2a15dcfb7ea5",
    name: "3 - 5 მ³",
  },
  {
    id: "c0e1bc25-0929-4f8c-8f61-eaf71a254bd5",
    name: "5 - 7 მ³",
  },
  {
    id: "d8d8e44d-bfca-4164-8016-868335df836b",
    name: ">7 მ³",
  },
];
export const garbageWasteTypes = [
  {
    id: "22268a71-9cad-474e-a789-5975d9649451",
    name: `სამშენებლო მასალა ტომრებით: ბეტონი, გაჯი, მეტლახი და ა.შ.`,
  },
  {
    id: "d1da70b0-9d61-4822-a0b8-1895b14ec8bf",
    name: "სამშენებლო ხის მასალა: კარი, ფანჯარა და ა.შ.",
  },
  {
    id: "b2e2dba1-bbb6-4ad1-bec0-f35df8d57ef3",
    name: "საყოფაცხოვრებო ავეჯი: კარადა, საწოლი და ა.შ.",
  },
  {
    id: "958f8e62-fc36-4cb6-99bc-e4e2628706cb",
    name: "ხის ტოტები / დასუფთავებული კერძო ბაღის ნარჩენები",
  },
];

export const regions = [
  {
    id: "fbc3652d-3163-4c4d-acd0-8c2ff91027a4",
    name: "თბილისი",
    nameEn: "Tbilisi",
    sortId: 1,
  },
  {
    id: "5cebb743-ea6a-4882-8620-794b37f3ca35",
    name: "აფხაზეთის ავტონომიური რესპუბლიკა",
    nameEn: "Autonomous Republic of Abkhazia",
    sortId: 2,
  },
  {
    id: "0306dd26-97d6-40e1-9248-5be29cda1936",
    name: "ბათუმი; (აჭარა)",
    nameEn: "Batumi, Adjara",
    sortId: 3,
  },
  {
    id: "087e3337-3e40-44dc-a8b3-629f6a61ae7a",
    name: "ქობულეთის მუნიციპალიტეტი; (აჭარა)",
    nameEn: "Kobuleti, Adjara,",
    sortId: 4,
  },
  {
    id: "f8ac8d52-ccbd-4b11-a35c-660db445735a",
    name: "ხელვაჩაურის მუნიციპალიტეტი; (აჭარა)",
    nameEn: "Khelvachauri, Adjara",
    sortId: 5,
  },
  {
    id: "13580f79-89cc-450c-bf1e-af6c716488dc",
    name: "ქედის მუნიციპალიტეტი; (აჭარა)",
    nameEn: "Keda, Adjara",
    sortId: 6,
  },
  {
    id: "f1589be2-0e7a-4841-bbc7-fe1ae22f1ecb",
    name: "შუახევის მუნიციპალიტეტი; (აჭარა)",
    nameEn: "Shuakhevi, Adjara",
    sortId: 7,
  },
  {
    id: "f965517e-fd21-4f63-87aa-749ef88def65",
    name: "ხულოს მუნიციპალიტეტი. (აჭარა)",
    nameEn: "Khulo, Adjara",
    sortId: 8,
  },
  {
    id: "d8660e15-6ddd-4bd9-94e0-05bc75ee701e",
    name: "ლანჩხუთის მუნიციპალიტეტი; (გურია)",
    nameEn: "Lanchkhuti, Guria",
    sortId: 9,
  },
  {
    id: "b5caa02b-a23b-436f-8b8a-e0e5f7c411f5",
    name: "ოზურგეთის მუნიციპალიტეტი; (გურია)",
    nameEn: "Ozurgeti, Guria",
    sortId: 10,
  },
  {
    id: "2ca79abc-8526-48e7-b1a2-1860dcac3906",
    name: "ჩოხატაურის მუნიციპალიტეტი; (გურია)",
    nameEn: "Chokhatauri, Guria",
    sortId: 11,
  },
  {
    id: "6099e965-bd5b-4fd2-bf75-0f2d81767e25",
    name: "ქუთაისი; (იმერეთი)",
    nameEn: "Kutaisi, Imereti",
    sortId: 12,
  },
  {
    id: "5299c64a-1a93-44af-b431-1981b486d6ec",
    name: "ზესტაფონის მუნიციპალიტეტი; (იმერეთი)",
    nameEn: "Zestaponi, Imereti",
    sortId: 13,
  },
  {
    id: "ac24b570-864d-4c3f-ba2e-11bb2e93e504",
    name: "წყალტუბოს მუნიციპალიტეტი; (იმერეთი)",
    nameEn: "Tskaltubo, Imereti",
    sortId: 14,
  },
  {
    id: "a4a542a8-f908-47cc-9f6b-79eb2c683776",
    name: "სამტრედიის მუნიციპალიტეტი; (იმერეთი)",
    nameEn: "Samtredia, Imereti",
    sortId: 15,
  },
  {
    id: "f2e1c80c-770e-430c-bf6d-bf7e237e698e",
    name: "ჭიათურის მუნიციპალიტეტი; (იმერეთი)",
    nameEn: "Chiatura, Imereti",
    sortId: 16,
  },
  {
    id: "e44ee988-ef6d-44d7-a293-8f9c98fba65b",
    name: "საჩხერის მუნიციპალიტეტი; (იმერეთი)",
    nameEn: "Sachkhere, Imereti",
    sortId: 17,
  },
  {
    id: "f7483727-a343-4c17-9fc7-14d6fd6b5af9",
    name: "თერჯოლის მუნიციპალიტეტი; (იმერეთი)",
    nameEn: "Terjola, Imereti",
    sortId: 18,
  },
  {
    id: "5aa6d2ef-0ff5-4592-a13d-498eb56cc9e4",
    name: "ვანის მუნიციპალიტეტი; (იმერეთი)",
    nameEn: "Vani, Imereti",
    sortId: 19,
  },
  {
    id: "a3585f3b-704c-4339-b7d8-0f7eca181f1a",
    name: "ხონის მუნიციპალიტეტი; (იმერეთი)",
    nameEn: "Khoni, Imereti",
    sortId: 20,
  },
  {
    id: "795bb283-d369-4da9-bec9-a42854527877",
    name: "ტყიბულის მუნიციპალიტეტი; (იმერეთი)",
    nameEn: "Tkibuli, Imereti",
    sortId: 21,
  },
  {
    id: "b01d45a7-3836-45b8-8dda-cc223bf029f9",
    name: "ბაღდათის მუნიციპალიტეტი; (იმერეთი)",
    nameEn: "Baghdati, Imereti",
    sortId: 22,
  },
  {
    id: "33814918-6670-4d21-b341-bd128f4e5e3d",
    name: "ხარაგაულის მუნიციპალიტეტი. (იმერეთი)",
    nameEn: "Kharagauli, Imereti",
    sortId: 23,
  },
  {
    id: "f161c4da-7896-4eab-b164-b40c59c2afaa",
    name: "თელავის მუნიციპალიტეტი;  (კახეთი)",
    nameEn: "Telavi, Kakheti",
    sortId: 24,
  },
  {
    id: "04392389-91ed-4f6c-bb02-9b640e948f3d",
    name: "ახმეტის მუნიციპალიტეტი; (კახეთი)",
    nameEn: "Akhmeta, Kakheti",
    sortId: 25,
  },
  {
    id: "2ef1e149-f639-4815-b24b-422e284727d2",
    name: "გურჯაანის მუნიციპალიტეტი; (კახეთი)",
    nameEn: "Gurjaani, Kakheti",
    sortId: 26,
  },
  {
    id: "74ddc827-1078-44ed-980b-504fbc3085cd",
    name: "ყვარლის მუნიციპალიტეტი; (კახეთი)",
    nameEn: "Kvareli, Kakheti",
    sortId: 27,
  },
  {
    id: "abcb0bb7-56ac-4b5f-8fe3-02d8758ec7d9",
    name: "დედოფლისწყაროს მუნიციპალიტეტი; (კახეთი)",
    nameEn: "Dedoplistskaro, Kakheti",
    sortId: 28,
  },
  {
    id: "04a7672e-58a1-4ff8-b1af-cdc6e2827e5a",
    name: "ლაგოდეხის მუნიციპალიტეტი; (კახეთი)",
    nameEn: "Lagodekhi, Kakheti",
    sortId: 29,
  },
  {
    id: "4a61323b-ec7f-47b3-9306-a8713ab07743",
    name: "საგარეჯოს მუნიციპალიტეტი; (კახეთი)",
    nameEn: "Sagarejo, Kakheti",
    sortId: 30,
  },
  {
    id: "ca2189b6-fcf1-4d85-b10d-1626e2693c22",
    name: "სიღნაღის მუნიციპალიტეტი; (კახეთი)",
    nameEn: "Sighnaghi, Kakheti",
    sortId: 31,
  },
  {
    id: "6b301dc6-645a-4f64-949e-44f78eb531d0",
    name: "ახალგორის მუნიციპალიტეტი; (მცხეთა-მთიანეთი)",
    nameEn: "Akhalgori, Mtskheta-Mtianeti",
    sortId: 32,
  },
  {
    id: "7b65fc07-0442-457c-b5aa-94c8d41462ee",
    name: "დუშეთის მუნიციპალიტეტი; (მცხეთა-მთიანეთი)",
    nameEn: "Dusheti, Mtskheta-Mtianeti",
    sortId: 33,
  },
  {
    id: "fe821678-05db-4bc2-9fbe-3e5d40cb8257",
    name: "თიანეთის მუნიციპალიტეტი; (მცხეთა-მთიანეთი)",
    nameEn: "Tianeti, Mtskheta-Mtianeti",
    sortId: 34,
  },
  {
    id: "3101d481-3263-4014-8a0b-936f720e4735",
    name: "მცხეთის მუნიციპალიტეტი; (მცხეთა-მთიანეთი)",
    nameEn: "Mtskheta, Mtskheta-Mtianeti",
    sortId: 35,
  },
  {
    id: "07152abb-7b03-42fe-a470-d422e3f185fb",
    name: "ყაზბეგის მუნუციპალიტეტი. (მცხეთა-მთიანეთი)",
    nameEn: "Kazbegi, Mtskheta-Mtianeti",
    sortId: 36,
  },
  {
    id: "a69eded9-aecf-4a35-84d2-03c8ae1db496",
    name: "ამბროლაურის მუნიციპალიტეტი; (რაჭა-ლეჩხუმი და ქვემო სვანეთი)",
    nameEn: "Ambrolauri, Racha-Lechkhumi and Kvemo Svaneti",
    sortId: 37,
  },
  {
    id: "e92cffec-d6b6-4f03-8c3e-f3212a334f25",
    name: "ლენტეხის მუნიციპალიტეტი; (რაჭა-ლეჩხუმი და ქვემო სვანეთი)",
    nameEn: "Lentekhi, Racha-Lechkhumi and Kvemo Svaneti",
    sortId: 38,
  },
  {
    id: "026fb99e-c625-4a0a-872d-d4c879642fcb",
    name: "ონის მუნიციპალიტეტი; (რაჭა-ლეჩხუმი და ქვემო სვანეთი)",
    nameEn: "Oni, Racha-Lechkhumi and Kvemo Svaneti",
    sortId: 39,
  },
  {
    id: "09ae7b2c-9a6e-4467-b1d8-04a87d87cef1",
    name: "ცაგერის მუნიციპალიტეტი; (რაჭა-ლეჩხუმი და ქვემო სვანეთი)",
    nameEn: "Tsageri, Racha-Lechkhumi and Kvemo Svaneti",
    sortId: 40,
  },
  {
    id: "b456fc55-e9ab-4907-87b9-982395d95fb8",
    name: "ფოთი; (სამეგრელო-ზემო სვანეთი)",
    nameEn: "Poti, Samegrelo-Zemo Svaneti",
    sortId: 41,
  },
  {
    id: "456c5a54-4f6b-4083-b845-7cf4011d5644",
    name: "აბაშის მუნიციპალიტეტი; (სამეგრელო-ზემო სვანეთი)",
    nameEn: "Abasha, Samegrelo-Zemo Svaneti",
    sortId: 42,
  },
  {
    id: "9f034d0a-472e-4ad8-91c2-d5ff01d1b6fc",
    name: "სენაკის მუნიციპალიტეტი; (სამეგრელო-ზემო სვანეთი)",
    nameEn: "Senaki, Samegrelo-Zemo Svaneti",
    sortId: 43,
  },
  {
    id: "b8148a53-0116-4427-bfc1-7ff7557f97db",
    name: "ზუგდიდის მუნიციპალიტეტი; (სამეგრელო-ზემო სვანეთი)",
    nameEn: "Zugdidi, Samegrelo-Zemo Svaneti",
    sortId: 44,
  },
  {
    id: "80eddc5e-d729-48fd-b889-ad2c3c59f6a7",
    name: "მარტვილის მუნიციპალიტეტი; (სამეგრელო-ზემო სვანეთი)",
    nameEn: "Martvili, Samegrelo-Zemo Svaneti",
    sortId: 45,
  },
  {
    id: "75eb4e40-18ef-4459-9590-12d5062968a9",
    name: "წალენჯიხის მუნიციპალიტეტი; (სამეგრელო-ზემო სვანეთი)",
    nameEn: "Tsalenjikha, Samegrelo-Zemo Svaneti",
    sortId: 46,
  },
  {
    id: "00508a22-da38-4905-b4bc-e6d9b9e9304e",
    name: "ხობის მუნიციპალიტეტი; (სამეგრელო-ზემო სვანეთი)",
    nameEn: "Khobi, Samegrelo-Zemo Svaneti",
    sortId: 47,
  },
  {
    id: "92580d22-f066-4a3c-8107-e787cb816972",
    name: "ჩხოროწყუს მუნიციპალიტეტი; (სამეგრელო-ზემო სვანეთი)",
    nameEn: "Chkhorotsku, Samegrelo-Zemo Svaneti",
    sortId: 48,
  },
  {
    id: "4baec2d2-89e7-49b7-9cb4-3d20b34ebe97",
    name: "მესტიის მუნიციპალიტეტი; (სამეგრელო-ზემო სვანეთი)",
    nameEn: "Mestia, Samegrelo-Zemo Svaneti",
    sortId: 49,
  },
  {
    id: "fd7e0b13-5b33-46c5-9eb2-23a9cb56033a",
    name: "ადიგენის მუნიციპალიტეტი; (სამცხე-ჯავახეთი)",
    nameEn: "Adigeni, Samtskhe-Javakheti",
    sortId: 50,
  },
  {
    id: "1e972822-cbd7-4ac6-8b9a-2f3fca011483",
    name: "ასპინძის მუნიციპალიტეტი; (სამცხე-ჯავახეთი)",
    nameEn: "Aspindza, Samtskhe-Javakheti",
    sortId: 51,
  },
  {
    id: "34cfa199-85b6-42d5-9994-7c8bba6b623d",
    name: "ახალქალაქის მუნიციპალიტეტი; (სამცხე-ჯავახეთი)",
    nameEn: "Akhalkalaki, Samtskhe-Javakheti",
    sortId: 52,
  },
  {
    id: "76f54b78-4284-494f-bf43-7b0076c33e05",
    name: "ახალციხის მუნიციპალიტეტი; (სამცხე-ჯავახეთი)",
    nameEn: "Akhaltsikhe, Samtskhe-Javakheti",
    sortId: 53,
  },
  {
    id: "c8bf7adf-74fb-4b5c-8dfd-6dec4232fec3",
    name: "ბორჯომის მუნიციპალიტეტი; (სამცხე-ჯავახეთი)",
    nameEn: "Borjomi, Samtskhe-Javakheti",
    sortId: 54,
  },
  {
    id: "6c7a482f-c176-41fe-a399-de6476d484bb",
    name: "ნინოწმინდის მუნიციპალიტეტი; (სამცხე-ჯავახეთი)",
    nameEn: "Ninotsminda, Samtskhe-Javakheti",
    sortId: 55,
  },
  {
    id: "3d4f462d-7191-4e89-89c1-460a78b3a71e",
    name: "რუსთავი; (ქვემო ქართლი)",
    nameEn: "Rustavi, Kvemo Kartli",
    sortId: 56,
  },
  {
    id: "29a3dbcc-6aea-4840-bb96-1c2bfd1dbe51",
    name: "გარდაბნის მუნიციპალიტეტი; (ქვემო ქართლი)",
    nameEn: "Gardabani, Kvemo Kartli",
    sortId: 57,
  },
  {
    id: "494648c4-3a94-46d8-89f4-3a5cba7a7c58",
    name: "ბოლნისის მუნიციპალიტეტი; (ქვემო ქართლი)",
    nameEn: "Bolnisi, Kvemo Kartli",
    sortId: 58,
  },
  {
    id: "8acb5a60-5bda-4bdf-90b2-255801bca693",
    name: "მარნეულის მუნიციპალიტეტი; (ქვემო ქართლი)",
    nameEn: "Marneuli, Kvemo Kartli",
    sortId: 59,
  },
  {
    id: "9ddbdc82-273e-4a01-899a-1021795343ae",
    name: "დმანისის მუნიციპალიტეტი; (ქვემო ქართლი)",
    nameEn: "Dmanisi, Kvemo Kartli",
    sortId: 60,
  },
  {
    id: "d973fdf9-45c1-4c6e-b77e-a756d9a12dd6",
    name: "თეთრიწყაროს მუნიციპალიტეტი; (ქვემო ქართლი)",
    nameEn: "Tetritskaro, Kvemo Kartli",
    sortId: 61,
  },
  {
    id: "4db31726-b4ff-4fb4-86ed-9705cfdfa935",
    name: "წალკის მუნიციპალიტეტი; (ქვემო ქართლი)",
    nameEn: "Tsalka, Kvemo Kartli",
    sortId: 62,
  },
  {
    id: "2cc26d44-8ba9-4a10-a3e0-6854e7ae2f66",
    name: "გორის მუნიციპალიტეტი; (შიდა ქართლი)",
    nameEn: "Gori, Shida Kartli",
    sortId: 63,
  },
  {
    id: "697d73a6-6b97-45a0-8043-9675607b7f88",
    name: "კასპის მუნიციპალიტეტი;  (შიდა ქართლი)",
    nameEn: "Kaspi, Shida Kartli",
    sortId: 64,
  },
  {
    id: "692baad7-9868-4385-97f4-73887d69c49d",
    name: "ქარელის მუნიციპალიტეტი;  (შიდა ქართლი)",
    nameEn: "Kareli, Shida Kartli",
    sortId: 65,
  },
  {
    id: "c5705a09-669a-4756-b74e-b959bedb71eb",
    name: "ხაშურის მუნიციპალიტეტი;  (შიდა ქართლი)",
    nameEn: "Khashuri, Shida Kartli",
    sortId: 66,
  },
  {
    id: "17af00d6-6088-4bc2-962c-5cab29356279",
    name: "ერედვის მუნიციპალიტეტი;  (შიდა ქართლი)",
    nameEn: "Eredvi, Shida Kartli",
    sortId: 67,
  },
  {
    id: "f4d0f062-3d7a-46e7-9027-5069b8bc6eeb",
    name: "ქურთის მუნიციპალიტეტი;  (შიდა ქართლი)",
    nameEn: "Kurta, Shida Kartli",
    sortId: 68,
  },
  {
    id: "11b60a70-2b00-48fc-ace4-58729af7ae47",
    name: "თიღვის მუნიციპალიტეტი;  (შიდა ქართლი)",
    nameEn: "Tighvi, Shida Kartli",
    sortId: 69,
  },
];
