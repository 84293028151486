import React, { useState, useEffect, useRef } from "react";
import "./MessagePage.css";
import { Modal, Form, Button } from "react-bootstrap";
import { getMessageById, addMessage } from "../../api/api";
import withAuth from "../../auth/withAuth";

const MessagePage = ({ onClose, token, userId, statementId }) => {
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const textAreaRef = useRef(null);

  const fetchMessages = async () => {
    try {
      const fetchedMessages = await getMessageById(token, statementId);
      const sortedMessages = fetchedMessages.sort(
        (a, b) => new Date(b.dateCreated) - new Date(a.dateCreated)
      );
      setMessages(sortedMessages);
    } catch (error) {
      console.error("Failed to fetch messages:", error);
    }
  };

  useEffect(() => {
    let isMounted = true;

    const fetchMessages = async () => {
      try {
        const fetchedMessages = await getMessageById(token, statementId);
        const sortedMessages = fetchedMessages.sort(
          (a, b) => new Date(b.dateCreated) - new Date(a.dateCreated)
        );
        if (isMounted) {
          setMessages(sortedMessages);
        }
      } catch (error) {
        console.error("Failed to fetch messages:", error);
      }
    };

    fetchMessages();

    return () => {
      isMounted = false;
    };
  }, []);

  const sendMessage = async () => {
    if (message.trim()) {
      try {
        const newMessage = {
          userId,
          statementId,
          messageText: message,
        };
        await addMessage(
          token,
          newMessage.userId,
          newMessage.statementId,
          newMessage.messageText
        );
        setMessage("");
        fetchMessages();
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };

  const handleChange = (event) => {
    setMessage(event.target.value);
    adjustTextAreaHeight();
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      sendMessage();
    }
  };

  const adjustTextAreaHeight = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "inherit";
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    adjustTextAreaHeight();
  }, [messages]);

  return (
    <Modal
      show={true}
      onHide={onClose}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          კომენტარები
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="message-container modalcontainermessage">
          {messages.map((msg) => (
            <div
              key={msg.id}
              className={`message-card modalcardmessage ${
                msg.userId === userId ? "float-right" : "float-left"
              }`}
            >
              <div className="message-text modaltextmessage">
                {msg.messageText}
              </div>
              <div className="message-time modaltimemessage">
                {new Date(msg.dateCreated).toLocaleTimeString()}
              </div>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Form.Control
          as="textarea"
          ref={textAreaRef}
          className="form-control modalcontrolmessage"
          value={message}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          placeholder="დაამატეთ კომენტარი..."
          style={{ overflow: "hidden", resize: "none" }}
        />
        <Button onClick={sendMessage} className="btn btn-success">
          გაგზავნა
        </Button>
        <Button onClick={onClose} className="btn btn-secondary">
          დახურვა
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default withAuth(MessagePage);
