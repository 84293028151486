import React, { useRef, useEffect } from "react";
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import { fromLonLat, toLonLat } from "ol/proj";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { Icon, Style } from "ol/style";
import { FaLocationArrow, FaPlus, FaMinus } from "react-icons/fa";
import "./addressmap.css";
import withAuth from "../../auth/withAuth";

function LocationPicker({ location, setLocation, readonly }) {
  const mapRef = useRef();
  const markerRef = useRef();
  const viewRef = useRef();
  const mapInstanceRef = useRef();
  const vectorSourceRef = useRef(new VectorSource());

  useEffect(() => {
    const defaultCoordinates = fromLonLat([44.793, 41.71]);
    const view = new View({
      center: defaultCoordinates,
      zoom: 12,
    });
    viewRef.current = view;

    const map = new Map({
      target: mapRef.current,
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      view: view,
      controls: [],
    });

    mapInstanceRef.current = map;

    const vectorLayer = new VectorLayer({
      source: vectorSourceRef.current,
      style: new Style({
        image: new Icon({
          scale: 0.08,
          anchor: [0.5, 1],
          src: process.env.PUBLIC_URL + "/location.png",
        }),
      }),
    });

    map.addLayer(vectorLayer);

    if (!readonly) {
      map.on("click", function (e) {
        const lonlat = toLonLat(e.coordinate);
        const newCoordinates = fromLonLat(lonlat);
        let marker = markerRef.current;

        if (!marker) {
          marker = new Feature({
            geometry: new Point(newCoordinates),
          });
          vectorSourceRef.current.addFeature(marker);
          markerRef.current = marker;
        } else {
          marker.setGeometry(new Point(newCoordinates));
        }

        view.animate({
          center: newCoordinates,
          duration: 1000,
        });
        setLocation({ latitude: lonlat[1], longitude: lonlat[0] });
        console.log("Coordinates on map click:", lonlat);
      });
    }

    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible" && mapInstanceRef.current) {
        mapInstanceRef.current.updateSize();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      map.setTarget(undefined);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [setLocation, readonly]);

  useEffect(() => {
    if (location.latitude && location.longitude) {
      const newCoordinates = fromLonLat([
        location.longitude,
        location.latitude,
      ]);
      let marker = markerRef.current;

      if (!marker) {
        marker = new Feature({
          geometry: new Point(newCoordinates),
        });
        vectorSourceRef.current.addFeature(marker);
        markerRef.current = marker;
      } else {
        marker.setGeometry(new Point(newCoordinates));
      }

      viewRef.current.animate({
        center: newCoordinates,
        zoom: viewRef.current.getZoom(),
        duration: 1000,
      });
    }
  }, [location]);

  const handleGeolocation = (event) => {
    event.preventDefault();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          console.log("Geolocation fetched:", { latitude, longitude });
          const newCoordinates = fromLonLat([longitude, latitude]);
          let marker = markerRef.current;

          if (!marker) {
            marker = new Feature({
              geometry: new Point(newCoordinates),
            });
            vectorSourceRef.current.addFeature(marker);
            markerRef.current = marker;
          } else {
            marker.setGeometry(new Point(newCoordinates));
          }

          viewRef.current.animate({
            center: newCoordinates,
            zoom: 16.6,
            duration: 1000,
          });
          setLocation({ latitude, longitude });
        },
        (error) => {
          console.error("Error getting geolocation:", error);
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0,
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const zoomIn = (event) => {
    event.preventDefault();
    const view = viewRef.current;
    view.animate({
      zoom: view.getZoom() + 1,
      duration: 500,
    });
  };

  const zoomOut = (event) => {
    event.preventDefault();
    const view = viewRef.current;
    view.animate({
      zoom: view.getZoom() - 1,
      duration: 500,
    });
  };

  return (
    <div className="map-container" style={{ height: "500px" }}>
      <div ref={mapRef} className="map"></div>
      <div className="map-buttons">
        <button className="zoom-button" onClick={zoomIn}>
          <FaPlus />
        </button>
        <button className="zoom-button" onClick={zoomOut}>
          <FaMinus />
        </button>
        <button className="geolocation-button" onClick={handleGeolocation}>
          <FaLocationArrow />
        </button>
      </div>
    </div>
  );
}

export default withAuth(LocationPicker);
