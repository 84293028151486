import "./Statements.css";
import WithAuth from "../../../auth/withAuth";
import withRouter from "../../../auth/withRouter";
import { Button, Modal } from "react-bootstrap";
import React, { useState } from "react";
import { homepage } from "../../../constants";
import { AiOutlineClose, AiOutlineCheck } from "react-icons/ai";

const statusDetails = {
  0: { phrase: "განხილვაში", color: "rgb(205, 123, 0)" },
  1: { phrase: "დასრულებული", color: "green" },
  5: { phrase: "გადახდილი", color: "green" },
  4: { phrase: "განთავსებული", color: "green" },
  10: { phrase: "დასადასტურებელი", color: "green" },
  15: { phrase: "გადასახდელი", color: "rgb(255,128,0)" },
  98: { phrase: "გაუქმებული", color: "rgb(205,0,0)" },
  99: { phrase: "უარყოფილი", color: "rgb(205,0,0)" },
};

function formatDate(dateString) {
  const dateObj = new Date(dateString);
  const year = dateObj.getFullYear();
  const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
  const day = dateObj.getDate().toString().padStart(2, "0");
  return `${day} / ${month} / ${year}`;
}

const Statements = (props) => {
  const {
    id,
    dateCreated,
    address,
    description,
    user,
    price,
    status,
    notSeenCommentCount,
    region,
  } = props.Statements;

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [actionType, setActionType] = useState("");

  const navigate = props.router.navigate;

  const handleInfoClick = (id) => {
    navigate(`${homepage}/statementInfo/${id}`);
  };

  const handleAcceptClick = async (id) => {
    try {
      await props.onAcceptClick(id);
      setModalMessage(<b>განცხადება დადასტურდა!</b>);
      setActionType("accept");
      setShowSuccessModal(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteClick = async (id) => {
    try {
      await props.onDeleteClick(id);
      setModalMessage(<b>განცხადება გაუქმდა!</b>);
      setActionType("reject");
      setShowSuccessModal(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handlePaymentClick = (id) => {
    navigate(`${homepage}/PaymentPage/${id}`);
  };

  const getStatusDetails = (status) => statusDetails[status];
  const { phrase, color } = getStatusDetails(status);

  return (
    <>
      <ul key={id} className="ListStyle">
        <div className="row statementsrow border">
          <div
            className="col statementinfo col-xl-2 col-lg-2 col-md-4 col-sm-12 col-12 "
            style={{ color: color, fontWeight: "bold" }}
          >
            {phrase}
          </div>
          <div className="col statementinfo col-xl-2 col-lg-2 col-md-4 col-sm-12 col-12">
            {formatDate(dateCreated)}
          </div>
          <div className="col statementinfo col-xl-2 col-lg-2 col-md-4 col-sm-12 col-12">
            {user.firstName + " " + user.lastName}
          </div>

          <div className="col statementinfo col-xl-2 col-lg-2 col-md-4 col-sm-12 col-12">
            {region.name}
          </div>
          <div className="col statementinfo col-xl-1 col-lg-2 col-md-4  col-sm-12 col-12">
            {price} {price === null ? "" : "₾"}
          </div>
          <div className="col buttonsgroup statementinfobutton statementinfo col-xl-2 col-lg-2 col-md-4 col-sm-12 col-12">
            {status === 10 ? (
              <>
                <Button
                  className="accept"
                  variant="outline-success"
                  size="sm"
                  data-toggle="tooltip"
                  title="თანხმობა"
                  onClick={() => handleAcceptClick(id)}
                >
                  <AiOutlineCheck />
                </Button>

                <Button
                  className="reject"
                  variant="outline-danger"
                  size="sm"
                  data-toggle="tooltip"
                  title="გაუქმება"
                  onClick={() => handleDeleteClick(id)}
                >
                  <AiOutlineClose />
                </Button>
              </>
            ) : null}

            {status === 15 ? (
              <Button
                className="payment"
                variant="outline-success"
                size="sm"
                data-toggle="tooltip"
                title="გადახდა"
                onClick={() => handlePaymentClick(id)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-wallet"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a2 2 0 0 1-1-.268M1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1" />
                </svg>
              </Button>
            ) : null}

            <Button
              className="info position-relative"
              variant="outline-secondary"
              size="sm"
              data-toggle="modal"
              title="დეტალური ინფორმაცია"
              data-target="#exampleModal"
              onClick={() => handleInfoClick(id)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-search"
                viewBox="0 0 16 16"
              >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
              </svg>
              {notSeenCommentCount > 0 && (
                <span className="badge">{notSeenCommentCount}</span>
              )}
            </Button>
          </div>
        </div>
      </ul>
      <Modal
        show={showSuccessModal}
        onHide={() => setShowSuccessModal(false)}
        style={{ marginTop: "150px", fontSize: "15px" }}
      >
        <Modal.Body
          style={{ color: actionType === "accept" ? "green" : "red" }}
        >
          {modalMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowSuccessModal(false)}
          >
            დახურვა
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default WithAuth(withRouter(Statements));
