import { SEND_SMS } from '../actions/ActionTypes'

const initialState = {
  smsResult: {},
}

const smsReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case SEND_SMS:
      return {
        smsResult: payload.result,
      }
    default:
      return state
  }
}

export default smsReducer
