import React from "react";
import { useState } from "react";
import withAuth from "../auth/withAuth";
import "./paymentFailure.css";
import { homepage } from "../constants";
import { redirect } from "react-router-dom";
import withRouter from "../auth/withRouter";
import { Button } from "react-bootstrap";

function PaymentFailure() {
  const [redirect, setRedirect] = useState(false);

  const handleRedirect = () => {
    setRedirect(true);
  };

  if (redirect) {
    return <redirect to={`${homepage}/my-requests`} />;
  }

  return (
    <div
      className="auth-block"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "55px",
      }}
    >
      <div className="container-fluid" style={{ textAlign: "center" }}>
        <div
          className="row"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div
            className="col col-12 login-half"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className="status-icon failure" style={{ marginTop: "-25px" }}>
              <i className="fa fa-times-circle"></i>
            </div>
            <p
              style={{ color: "red", marginTop: "-15px" }}
              className="status-message failure-message"
            >
              გადახდა ვერ განხორციელდა!
            </p>
            <div className="form-group">
              <Button variant="outline-danger" onClick={handleRedirect}>
                განცხადებების გვერდზე დაბრუნება
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withAuth(withRouter(PaymentFailure));
