import React from "react";
import { connect } from "react-redux";
import { phoneNumberChange } from "../../actions/Actions";
import { loggedOut } from "../../auth/authService";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import withRouter from "../../auth/withRouter";
import { homepage } from "../../constants";
import { Form, InputGroup, Button } from "react-bootstrap";
import { authorize } from "../../auth/authService";
import SmsControl from "../controls/sms/SmsControl";
import "./PhoneNumberChange.css";

class PhoneNumberChange extends React.Component {
  state = {
    privateNumber: "",
    password: "",
    phoneNumber: "",
    accept: true,
    loginData: null,
    smsCode: "",
    registerButtonDisable: false,
    actionType: 4,
  };

  componentDidMount() {
    console.log("phonechangeCDM");
    loggedOut();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.smsSecconds === 0 &&
      prevState.smsSecconds !== this.state.smsSecconds
    ) {
      this.setState({ smsSecconds: 10, smsShow: false });
      clearInterval(this.state.smsInterval);
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value }, () => {
      this.validateForm();
    });
  };
  handleGoBack = () => {
    this.props.router.navigate(`${homepage}/login`);
  };
  handleCheckChange = (event) => {
    const { name, value, checked, type } = event.target;
    this.setState({ [name]: type === "checkbox" ? checked : value });
  };

  handlePhoneNumberChange = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() && this.validateForm()) {
      const { privateNumber, smsCode, password, phoneNumber } = this.state;
      await this.props.phoneNumberChange(
        privateNumber,
        smsCode,
        password,
        phoneNumber
      );
      const { token, success, messages } = this.props.login;
      if (success) {
        authorize(token);
        this.props.router.navigate(`${homepage}/main-page`);
      } else {
        const mst = messages.join("\n*");
        Swal.fire(mst);
      }
    }
  };

  validateForm = () => {
    const { privateNumber, phoneNumber, smsCode, actionType } = this.state;
    return (
      privateNumber.length === 11 &&
      phoneNumber.length === 9 &&
      smsCode.length === 4
    );
  };

  render() {
    const {
      privateNumber,
      password,
      phoneNumber,
      smsCode,
      smsSendButton,
      actionType,
    } = this.state;
    return (
      <>
        <div className="phone-number-change-block">
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
              }}
            >
              <h4 style={{ margin: "0 auto" }}>ნომრის განახლება</h4>
              <Button
                size="sm"
                variant="outline-secondary"
                onClick={this.handleGoBack}
                title="დაბრუნება"
                style={{ position: "absolute", right: "15px", top: "12px" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="black"
                  className="bi bi-arrow-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
                  />
                </svg>
              </Button>
            </div>

            <Form
              noValidate
              validated={this.validateForm()}
              onSubmit={this.handleRegistration}
            >
              <Form.Group controlId="validationForm1">
                <span className="necessey-field"></span>
                <Form.Label className="font-weight-bold"></Form.Label>
                <label className="labelsForNumberChange" for="ID Number">
                  პირადი ნომერი
                </label>

                <Form.Control
                  className="inputValues"
                  autoComplete="off"
                  required
                  type="number"
                  name="privateNumber"
                  placeholder=""
                  value={privateNumber}
                  onChange={this.handleChange}
                  isInvalid={
                    privateNumber.length !== 11 && privateNumber.length > 0
                  }
                  isValid={privateNumber.length === 11}
                />
                <Form.Control.Feedback type="invalid">
                  პირადი ნომერი არ უნდა აღემატებოდეს 11 სიმბოლოს!
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="validationForm9">
                {/* <span className="necessey-field">*</span> */}
                <Form.Label className="font-weight-bold"></Form.Label>
                <label className="labelsForNumberChange" for="Password">
                  შეიყვანეთ პაროლი
                </label>
                <Form.Control
                  className="inputValues"
                  autoComplete="off"
                  required
                  type="password"
                  name="password"
                  placeholder=""
                  value={password}
                  onChange={this.handleChange}
                  // isValid={Email.trim() !== ""}
                />
              </Form.Group>

              <Form.Group controlId="validationForm7">
                <span className="necessey-field"></span>
                <Form.Label className="font-weight-bold"></Form.Label>
                <label className="labelsForNumberChange" for="New Phone Number">
                  ახალი ტელეფონის ნომერი
                </label>
                <InputGroup>
                  <Form.Control
                    className="inputValues"
                    autoComplete="off"
                    required
                    type="number"
                    name="phoneNumber"
                    placeholder=""
                    value={phoneNumber}
                    onChange={this.handleChange}
                    isInvalid={
                      phoneNumber.length !== 9 && phoneNumber.length > 0
                    }
                    isValid={phoneNumber.length === 9}
                  />

                  <SmsControl
                    {...{
                      privateNumber,
                      phoneNumber,
                      password,
                      actionType,
                    }}
                    //getSmsData={smsCode => this.setState({ smsCode })}
                    //smsType='registration'
                    disabled={
                      !(privateNumber.length === 11 && phoneNumber.length === 9)
                    }
                  />

                  <Form.Control.Feedback type="invalid">
                    მობილური ტელეფონის N
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group controlId="validationForm8">
                <span className="necessey-field"></span>
                <Form.Label className="font-weight-bold"></Form.Label>
                <label className="labelsForNumberChange" for="SMS Code">
                  სმს კოდი
                </label>
                <Form.Control
                  className="inputValues"
                  autoComplete="off"
                  required
                  type="number"
                  name="smsCode"
                  placeholder=""
                  value={smsCode}
                  onChange={this.handleChange}
                  disabled={smsSendButton}
                  isInvalid={smsCode.length !== 4 && smsCode.length > 0}
                  isValid={smsCode.length === 4}
                />

                <Form.Control.Feedback type="invalid">
                  სმს კოდი შეავსეთ კორექტულად!
                </Form.Control.Feedback>
              </Form.Group>
              <br />
              <div className="form-group">
                <button
                  disabled={
                    !this.validateForm() || this.state.registerButtonDisable
                  }
                  className="btn btn-block btnchange"
                  onClick={this.handlePhoneNumberChange}
                >
                  განახლება
                </button>
              </div>
            </Form>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps({ phoneNumberChangeReducer: { login } }) {
  return { login };
}

export default withRouter(
  connect(mapStateToProps, { phoneNumberChange })(PhoneNumberChange)
);
