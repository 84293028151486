import React from "react";
import { loggedIn, getProfile, getToken, loggedOut } from "./authService";
import { homepage } from "../constants";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const withAuth = (AuthComponent) => (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!loggedIn()) {
      navigate(`${homepage}/welcome-page`);
    }
  }, [navigate]);

  if (loggedIn()) {
    return (
      <AuthComponent
        {...props}
        user={getProfile()}
        token={getToken()}
        logOut={loggedOut}
      />
    );
  } else {
    return null;
  }
};

export default withAuth;
