import { combineReducers } from "redux";
import loginReducer from "./authorizationReducer";
import smsReducer from "./smsReducer";
import passwordRecoveryReducer from "./passwordRecoveryReducer";
import phoneNumberChangeReducer from "./phoneNumberChangeReducer";
import personReducer from "./personReducer"
import pensionStatementsReducer from "./pensionStatementsReducer";
import addPensionStatementReducer from "./addPensionStatementReducer"
import personCRADataReducer from "./personCRADataReducer";
import pensionStatementByIdReducer from "./pensionStatementByIdReducer";

const rootReducer = combineReducers({
  loginReducer,
  personReducer,
  pensionStatementsReducer,
  passwordRecoveryReducer,
  phoneNumberChangeReducer,
  smsReducer,
  addPensionStatementReducer,
  personCRADataReducer,
  pensionStatementByIdReducer

});

export default rootReducer;
