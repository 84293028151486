import decode from "jwt-decode";
import Cookies from "js-cookie";

export const getToken = () => {
  const token = Cookies.get("_token");
  console.log("getToken:", token);
  return token;
};

export const getProfile = () => {
  const token = getToken();
  console.log("getProfile token:", token);
  return token ? decode(token) : null;
};

export const loggedOut = () => {
  console.log("loggedOut: token removed");
  Cookies.remove("_token");
};

export const authorize = (token) => {
  try {
    Cookies.set("_token", token);
    console.log("authorize: token set", token);
    return token;
  } catch (err) {
    console.error("authorize error:", err);
    throw err;
  }
};

export const loggedIn = () => {
  const token = getToken();
  if (token) {
    const decoded = decode(token);
    console.log("loggedIn: decoded token", decoded);
    if (!(decoded.exp < Date.now() / 1000)) {
      console.log("TokenValid");
    }
    //return !(decoded.exp < Date.now() / 1000);
    return true;
  }
  return false;
};
