import React from "react";
import "./NotFound.css";

export default function NotFound() {
  return (
    <div className="page-not-found">
      <h1>ERROR 404</h1>
      <h3>გვერდი ვერ მოიძებნა!</h3>
    </div>
  );
}
