import { ADD_PENSION_STATEMENT } from '../actions/ActionTypes'

const initialState = {
  addPensionStatementResult: {},
}

const addPensionStatementReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case ADD_PENSION_STATEMENT:
      return {
        addPensionStatementResult: payload.result,
      }
    default:
      return state
  }
}

export default addPensionStatementReducer
