export const AUTHORIZATION_LOAD_BEGIN = 'AUTHORIZATION_LOAD_BEGIN'
export const AUTHORIZATION_LOAD_SUCCESS = 'AUTHORIZATION_LOAD_SUCCESS'
export const AUTHORIZATION_LOAD_ERROR = 'AUTHORIZATION_LOAD_ERROR'

export const PASSWORD_RECOVERY_LOAD_BEGIN = 'PASSWORD_RECOVERY_LOAD_BEGIN'
export const PASSWORD_RECOVERY_LOAD_SUCCESS = 'PASSWORD_RECOVERY_LOAD_SUCCESS'
export const PASSWORD_RECOVERY_LOAD_ERROR = 'PASSWORD_RECOVERY_LOAD_ERROR'

export const PHONE_NUMBER_CHANGE_LOAD_BEGIN = 'PHONE_NUMBER_CHANGE_LOAD_BEGIN'
export const PHONE_NUMBER_CHANGE_LOAD_SUCCESS = 'PHONE_NUMBER_CHANGE_LOAD_SUCCESS'
export const PHONE_NUMBER_CHANGE_LOAD_ERROR = 'PHONE_NUMBER_CHANGE_LOAD_ERROR'

export const SEND_SMS = 'SEND_SMS'
export const UPLOAD_FILES = 'UPLOAD_FILES'
export const ADD_PENSION_STATEMENT = 'ADD_PENSION_STATEMENT'



export const PERSON_LOAD_BEGIN = 'PERSON_LOAD_BEGIN'
export const PERSON_LOAD_SUCCESS = 'PERSON_LOAD_SUCCESS'
export const PERSON_LOAD_ERROR = 'PERSON_LOAD_ERROR'


export const PERSON_CRA_DATA_LOAD_BEGIN = 'PERSON_CRA_DATA_LOAD_BEGIN'
export const PERSON_CRA_DATA_LOAD_SUCCESS = 'PERSON_CRA_DATA_LOAD_SUCCESS'
export const PERSON_CRA_DATA_LOAD_ERROR = 'PERSON_CRA_DATA_LOAD_ERROR'

export const PENSION_STATEMENTS_LOAD_BEGIN = 'PENSION_STATEMENTS_LOAD_BEGIN'
export const PENSION_STATEMENTS_LOAD_SUCCESS = 'PENSION_STATEMENTS_LOAD_SUCCESS'
export const PENSION_STATEMENTS_LOAD_ERROR = 'PENSION_STATEMENTS_LOAD_ERROR'

export const PENSION_STATEMENT_BY_ID_LOAD_BEGIN = 'PENSION_STATEMENT_BY_ID_LOAD_BEGIN'
export const PENSION_STATEMENT_BY_ID_LOAD_SUCCESS = 'PENSION_STATEMENT_BY_ID_LOAD_SUCCESS'
export const PENSION_STATEMENT_BY_ID_LOAD_ERROR = 'PENSION_STATEMENT_BY_ID_LOAD_ERROR'




