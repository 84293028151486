import React from "react";
import { connect } from "react-redux";
import { authorization } from "../../actions/Actions";
import { loggedOut } from "../../auth/authService";
import Swal from "sweetalert2";
import withRouter from "../../auth/withRouter";
import { homepage } from "../../constants";
import { Form, InputGroup, Button, Modal } from "react-bootstrap";
import { authorize } from "../../auth/authService";
import SmsControl from "../controls/sms/SmsControl";
import PasswordStrengthBar from "react-password-strength-bar";
import "./Register.css";

class Register extends React.Component {
  state = {
    privateNumber: "",
    firstName: "",
    lastName: "",
    birthDate: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmedPassword: "",
    accept: true,
    loginData: null,
    smsCode: "",
    registerButtonDisable: false,
    actionType: 2,
    isChecked: false,
    showModal: false,
  };

  componentDidMount() {
    console.log("RegisterCDM");
    loggedOut();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.smsSecconds === 0 &&
      prevState.smsSecconds !== this.state.smsSecconds
    ) {
      this.setState({ smsSecconds: 10, smsShow: false });
      clearInterval(this.state.smsInterval);
    }
  }
  handleGoBack = () => {
    this.props.router.navigate(`${homepage}/login`);
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value }, () => {
      this.validateForm();
    });
  };

  handleCheckChange = (event) => {
    const { name, value, checked, type } = event.target;
    this.setState({ [name]: type === "checkbox" ? checked : value });
  };

  handleCheckboxChange = (event) => {
    this.setState({ isChecked: event.target.checked });
  };

  handleRegistration = async (event) => {
    event.preventDefault();
    console.log("registration Enter", this.props);
    const form = event.currentTarget;
    if (form.checkValidity() && this.validateForm()) {
      const { privateNumber, phoneNumber, smsCode } = this.state;
      const isRegistration = true;
      await this.props.authorization(
        privateNumber,
        phoneNumber,
        smsCode,
        isRegistration
      );
      const { token, success, messages } = this.props.login;
      if (success) {
        authorize(token);
        this.props.router.navigate(`${homepage}/main-page`);
      } else {
        const mst = messages.join("\n*");
        Swal.fire(mst);
      }
    }
  };

  openModal = () => this.setState({ showModal: true });
  closeModal = () => this.setState({ showModal: false });

  validateForm = () => {
    const {
      privateNumber,
      firstName,
      lastName,
      birthDate,
      email,
      phoneNumber,
      smsCode,
    } = this.state;
    return (
      privateNumber.length === 11 &&
      lastName.length > 0 &&
      phoneNumber.length === 9 &&
      smsCode.length === 4
    );
  };

  render() {
    const {
      privateNumber,
      firstName,
      lastName,
      birthDate,
      phoneNumber,
      email,
      password,
      confirmedPassword,
      showModal,
      smsCode,
      smsSendButton,
      actionType,
    } = this.state;
    return (
      <>
        <div className="register-block" id="bechdva">
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
              }}
            >
              <h4 style={{ margin: "0 auto" }}>რეგისტრაცია</h4>
              <Button
                size="sm"
                variant="outline-secondary"
                onClick={this.handleGoBack}
                title="დაბრუნება"
                style={{ position: "absolute", right: "15px", top: "12px" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="black"
                  className="bi bi-arrow-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
                  />
                </svg>
              </Button>
            </div>

            <Form
              noValidate
              validated={this.validateForm()}
              onSubmit={this.handleRegistration}
            >
              <Form.Group controlId="validationForm1">
                <span className="necessey-field"></span>
                <Form.Label className="font-weight-bold"></Form.Label>
                <label className="labelsForRegistration" for="ID Number">
                  პირადი ნომერი
                </label>
                <Form.Control
                  className="inputValues"
                  autoComplete="off"
                  required
                  type="number"
                  name="privateNumber"
                  placeholder=""
                  value={privateNumber}
                  onChange={this.handleChange}
                  isInvalid={
                    privateNumber.length !== 11 && privateNumber.length > 0
                  }
                  isValid={privateNumber.length === 11}
                />
                <Form.Control.Feedback type="invalid">
                  პირადი ნომერი არ უნდა აღემატებოდეს 11 სიმბოლოს!
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationForm2">
                <span className="necessey-field"></span>
                <Form.Label className="font-weight-bold"></Form.Label>
                <label className="labelsForRegistration" for="First Name">
                  სახელი
                </label>
                <Form.Control
                  className="inputValues"
                  autoComplete="off"
                  required
                  type="text"
                  name="firstName"
                  placeholder=""
                  value={firstName}
                  onChange={this.handleChange}
                  isValid={firstName.trim() !== ""}
                />
                <Form.Control.Feedback type="invalid">
                  შეავსეთ სახელი!
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationForm3">
                <span className="necessey-field"></span>
                <Form.Label className="font-weight-bold"></Form.Label>
                <label className="labelsForRegistration" for="Last Name">
                  გვარი
                </label>
                <Form.Control
                  className="inputValues"
                  autoComplete="off"
                  required
                  type="text"
                  name="lastName"
                  placeholder=""
                  value={lastName}
                  onChange={this.handleChange}
                  isValid={lastName.trim() !== ""}
                />
                <Form.Control.Feedback type="invalid">
                  შეავსეთ გვარი!
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="validationForm6">
                {/* <span className="necessey-field">*</span> */}
                <Form.Label className="font-weight-bold"></Form.Label>
                <label className="labelsForRegistration" for="Email">
                  ელ-ფოსტა
                </label>
                <Form.Control
                  className="inputValues"
                  autoComplete="off"
                  // required
                  type="text"
                  name="email"
                  placeholder=""
                  value={email}
                  onChange={this.handleChange}
                  // isValid={Email.trim() !== ""}
                />
              </Form.Group>
              <Form.Group controlId="validationForm9">
                {/* <span className="necessey-field">*</span> */}
                <Form.Label className="font-weight-bold"></Form.Label>
                <label className="labelsForRegistration" for="Password">
                  პაროლი
                </label>
                <Form.Control
                  className="inputValues"
                  autoComplete="off"
                  required
                  type="password"
                  name="password"
                  placeholder=""
                  value={password}
                  onChange={this.handleChange}
                  // isValid={Email.trim() !== ""}
                />
                <PasswordStrengthBar
                  password={password}
                  scoreWords={[
                    "სუსტი",
                    "სუსტი",
                    "დამაკმაყოფილებელი",
                    "კარგი",
                    "ძლიერი",
                  ]}
                  shortScoreWord={"პაროლი მოკლეა"}
                  minLength={8}
                />
              </Form.Group>
              <Form.Group controlId="validationForm10">
                {/* <span className="necessey-field">*</span> */}
                <Form.Label className="font-weight-bold"></Form.Label>
                <label className="labelsForRegistration" for="გაიმეორეთ პაროლი">
                  გაიმეორეთ პაროლი
                </label>
                <Form.Control
                  className="inputValues"
                  autoComplete="off"
                  required
                  type="password"
                  name="confirmedPassword"
                  placeholder=""
                  value={confirmedPassword}
                  onChange={this.handleChange}
                  isValid={
                    confirmedPassword !== "" && confirmedPassword === password
                  }
                />
              </Form.Group>
              <Form.Group controlId="validationForm7">
                <span className="necessey-field"></span>
                <Form.Label className="font-weight-bold"></Form.Label>
                <label className="labelsForRegistration" for="Phone Number">
                  მობილური ნომერი
                </label>
                <InputGroup>
                  <Form.Control
                    className="inputValues"
                    autoComplete="off"
                    required
                    type="number"
                    name="phoneNumber"
                    placeholder=""
                    value={phoneNumber}
                    onChange={this.handleChange}
                    isInvalid={
                      phoneNumber.length !== 9 && phoneNumber.length > 0
                    }
                    isValid={phoneNumber.length === 9}
                  />

                  <SmsControl
                    {...{
                      privateNumber,
                      firstName,
                      lastName,
                      birthDate,
                      email,
                      phoneNumber,
                      password,
                      confirmedPassword,
                      actionType,
                    }}
                    //getSmsData={smsCode => this.setState({ smsCode })}
                    //smsType='registration'
                    disabled={
                      !(
                        privateNumber.length === 11 &&
                        lastName.length > 0 &&
                        phoneNumber.length === 9
                      )
                    }
                  />

                  <Form.Control.Feedback type="invalid">
                    მობილური ტელეფონის N
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group controlId="validationForm8">
                <span className="necessey-field"></span>
                <Form.Label className="font-weight-bold"></Form.Label>
                <label className="labelsForRegistration" for="SMS Code">
                  სმს კოდი
                </label>
                <Form.Control
                  className="inputValues"
                  autoComplete="off"
                  required
                  type="number"
                  name="smsCode"
                  placeholder=""
                  value={smsCode}
                  onChange={this.handleChange}
                  disabled={smsSendButton}
                  isInvalid={smsCode.length !== 4 && smsCode.length > 0}
                  isValid={smsCode.length === 4}
                />

                <Form.Control.Feedback type="invalid">
                  სმს კოდი შეავსეთ კორექტულად!
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="custom-checkbox">
                <Form.Check
                  type="checkbox"
                  label={
                    <>
                      <span>ვეთანხმები </span>
                      <span
                        onClick={(e) => {
                          e.preventDefault(); // Prevent checkbox from being checked on modal open
                          this.openModal();
                        }}
                        style={{
                          color: "green",
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                      >
                        წესებსა და პირობებს
                      </span>
                    </>
                  }
                  id="termsCheckbox"
                  // checked={isChecked}
                  onChange={this.handleCheckboxChange}
                  style={{
                    marginTop: "10px",
                    marginBottom: "-9px",
                    fontSize: "14px",
                  }}
                />
              </Form.Group>
              <br />
              <div className="form-group">
                <button
                  disabled={
                    !this.validateForm() ||
                    this.state.registerButtonDisable ||
                    !this.state.isChecked
                  }
                  className=" btn-block btn btnregister"
                  onClick={this.handleRegistration}
                >
                  რეგისტრაცია
                </button>
              </div>
            </Form>
            <Modal show={showModal} onHide={this.closeModal} size="lg">
              <Modal.Header>
                <Modal.Title>წესები და პირობები</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  {/* Your HTML content for terms and conditions */}
                  <p>
                    თქვენ, ამ მოქმედების საფუძველზე ადასტურებთ, თქვენს
                    თანხმობას, იმ პერსონალური მონაცემების დამუშავების შესახებ,
                    რომელთაც გვაწვდით როდესაც სარგებლობთ ჩვენი ინტერნეტ
                    პლატფორმით და ჩვენი საქმიანობიდან გამომდინარე მომსახურებით,
                    ადასტურებთ და აცხადებთ თანხმობას თქვენ მიერ მოწოდებული
                    პერსონალური მონაცემების დამუშავების შესახებ.
                  </p>
                  <p>
                    გთხოვთ, გაითვალისწინოთ, რომ თქვენ მიერ მოწოდებული
                    პერსონალური მონაცემები მუშავდება შპს "თბილსერვის ჯგუფის"-ს
                    მიერ, მისამართი: საქართველო, თბილისი, კახეთის გზატკეცილი
                    N67; საიდენტიფიკაციო ნომერი: 206267494. თქვენ მიერ,
                    პერსონალური მონაცემების დამუშავების შესახებ თანხმობით
                    აცხადებთ და ადასტურებთ, რომ აღნიშნული პერსონალური
                    მონაცემების, მათ შორის, სახელი და გვარი, პირადი ნომერი,
                    მისამართი, ელ-ფოსტის მისამართი, ტელეფონის ნომერი, მობილურის
                    ნომერი, ან/და სხვა სახის პერსონალური ინფორმაციის დამუშავება
                    შესაძლოა განხორციელდეს შემდეგი მიზნ(ებ)ით:
                  </p>
                  <ul>
                    <li>
                      კომპანიის საქმიანობის ფარგლებში და მისგან გამომდინარე
                      მომსახურების გაწევის მიზნით
                    </li>
                  </ul>
                  <p>
                    გთხოვთ, გაითვალისწინოთ, რომ თუ თქვენ ინფორმაციას ავსებთ სხვა
                    პირის, სახელით, კომპანიის მიერ ეს აღიქმება იმგვარად, რომ
                    თქვენ ხართ შესაბამისი უფლებამოსილი წარმომადგენელი და
                    გაგაჩნიათ უფლებამოსილება მოგვცეთ ყველა საჭირო თანხმობა, რათა
                    ჩვენ მიერ პერსონალური მონაცემები დამუშავებულ იქნეს
                    წინამდებარე დოკუმენტში მოცემული მიზნებისთვის.
                  </p>
                  <p>
                    გთხოვთ, გაითვალისწინოთ, რომ თქვენ ნებისმიერ დროს შეგიძლიათ
                    თქვათ უარი თქვენ მიერ გაცხადებულ თანხმობაზე პერსონალური
                    მონაცემების დამუშავების შესახებ, რა შემთხვევაშიც კომპანია
                    შეწყვეტს თქვენი იმ პერსონალური მონაცემების დამუშავებას,
                    რომლის დამუშავებაზეც თქვენ განაცხადეთ უარი ასევე
                    მომსახურების მიწოდებას. აღნიშნული გადაწყვეტილების მიღების
                    შემდეგ გაგიუქმდებათ tgservice.ge-ს მომხმარებლის სტატუსი.
                  </p>
                  <p>
                    ჩვენ შევინახავთ თქვენ პერსონალურ მონაცემებს და დავამუშავებთ
                    მათ წინამდებარე დოკუმენტში მოცემული მიზნებისთვის, იმ
                    ხანგრძლივობით რაც საჭიროა ამ დოკუმენტში მითითებული
                    საქმიანობის შესასრულებლად, ან იმდენი ხნით, რამდენი ხანიც ეს
                    დაშვებულია მოქმდი კანონმდებლობით.
                  </p>
                  <p>
                    აღნიშნული თანხმობით ადასტურებთ კომპანიასა და თქვენს შორის
                    მომსახურების ხელშეკრულების არსებობას.
                  </p>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.closeModal}>
                  დახურვა
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps({ loginReducer: { login } }) {
  return { login };
}

export default withRouter(
  connect(mapStateToProps, { authorization })(Register)
);
