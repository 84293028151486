import React, { useRef, useEffect } from "react";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import { Feature } from "ol";
import Point from "ol/geom/Point";
import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import { Icon, Style, Text, Fill } from "ol/style";
import { defaults as defaultControls } from "ol/control";
import { FaPlus, FaMinus, FaLocationArrow } from "react-icons/fa";
import "./addressmap.css";

import { transform } from "ol/proj";

function OLMap2() {
  const mapRef = useRef();
  const viewRef = useRef();

  useEffect(() => {
    const markerCoordinates = [44.781786813492616, 41.77621101544676];

    const transformedMarkerCoordinates = transform(
      markerCoordinates,
      "EPSG:4326",
      "EPSG:3857"
    );

    const marker = new Feature({
      geometry: new Point(transformedMarkerCoordinates),
      name: "Your Location",
    });

    const vectorSource = new VectorSource({
      features: [marker],
    });

    const markerStyle = new Style({
      image: new Icon({
        src: process.env.PUBLIC_URL + "/location.png",
        scale: 0.06,
        anchor: [0.5, 1],
      }),
      text: new Text({
        offsetY: -15,
        fill: new Fill({
          color: "#000",
        }),
      }),
    });

    marker.setStyle(markerStyle);

    const vectorLayer = new VectorLayer({
      source: vectorSource,
    });

    const view = new View({
      center: transformedMarkerCoordinates,
      zoom: 17,
    });

    const map = new Map({
      target: mapRef.current,
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
        vectorLayer,
      ],
      view: view,
      controls: defaultControls({ zoom: false }),
    });

    viewRef.current = view; // Store the view reference for zoom controls
  }, []);

  const zoomIn = (event) => {
    event.preventDefault();
    const view = viewRef.current;
    view.animate({
      zoom: view.getZoom() + 1,
      duration: 500,
    });
  };

  const zoomOut = (event) => {
    event.preventDefault();
    const view = viewRef.current;
    view.animate({
      zoom: view.getZoom() - 1,
      duration: 500,
    });
  };

  const handleGeolocation = (event) => {
    event.preventDefault();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          console.log("Geolocation fetched:", { latitude, longitude });
          const newCoordinates = transform(
            [longitude, latitude],
            "EPSG:4326",
            "EPSG:3857"
          );
          let marker = new Feature({
            geometry: new Point(newCoordinates),
          });

          marker.setStyle(
            new Style({
              image: new Icon({
                src: process.env.PUBLIC_URL + "/location.png",
                scale: 0.06,
                anchor: [0.5, 1],
              }),
              text: new Text({
                offsetY: -15,
                fill: new Fill({
                  color: "#000",
                }),
              }),
            })
          );

          const vectorSource = new VectorSource({
            features: [marker],
          });

          const vectorLayer = new VectorLayer({
            source: vectorSource,
          });

          const map = new Map({
            target: mapRef.current,
            layers: [
              new TileLayer({
                source: new OSM(),
              }),
              vectorLayer,
            ],
            view: new View({
              center: newCoordinates,
              zoom: 17,
            }),
            controls: defaultControls({ zoom: false }),
          });

          viewRef.current = map.getView();
        },
        (error) => {
          console.error("Error getting geolocation:", error);
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0,
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  return (
    <div
      className="map-container"
      style={{ position: "relative", height: "400px" }}
    >
      <div ref={mapRef} style={{ width: "100%", height: "100%" }}></div>
      <div className="map-buttons">
        <button className="zoom-button" onClick={zoomIn}>
          <FaPlus />
        </button>
        <button className="zoom-button" onClick={zoomOut}>
          <FaMinus />
        </button>
      </div>
    </div>
  );
}

export default OLMap2;
