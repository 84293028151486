import {
  PERSON_CRA_DATA_LOAD_BEGIN,
  PERSON_CRA_DATA_LOAD_SUCCESS,
  PERSON_CRA_DATA_LOAD_ERROR
} from "../actions/ActionTypes";

const initialState = {
  personCRAData: null,
  success: false,
  loader: false,
  error: null,
};

const personCRADataReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case PERSON_CRA_DATA_LOAD_BEGIN:
      return { ...state, loader: true, error: null };
    case PERSON_CRA_DATA_LOAD_SUCCESS:
      console.log("cradata",payload.result)
      return { ...state, personCRAData: payload.result,success:true, loader: false, error: null };
    case PERSON_CRA_DATA_LOAD_ERROR:
      return { ...state, error: payload, loader: false };
    default:
      return state;
  }
};

export default personCRADataReducer;
