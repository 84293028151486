import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { fetchStatementImage } from "../../api/api";
import withAuth from "../../auth/withAuth";

class Gallery extends Component {
  state = {
    isFullImageModalVisible: false,
    selectedImage: null,
    currentIndex: 0,
    isGalleryModalVisible: false,
  };

  handleThumbnailClick = async (index) => {
    const image = this.props.images[index];
    try {
      const imageUrl = await fetchStatementImage(
        this.props.token,
        image.statementId,
        image.name
      );
      this.setState({
        selectedImage: imageUrl,
        isFullImageModalVisible: true,
        currentIndex: index,
      });
    } catch (error) {
      console.error("Error fetching full image:", error);
    }
  };

  handlePrevClick = () => {
    const newIndex = this.state.currentIndex - 1;
    if (newIndex >= 0) {
      this.handleThumbnailClick(newIndex);
    }
  };

  handleNextClick = () => {
    const newIndex = this.state.currentIndex + 1;
    if (newIndex < this.props.images.length) {
      this.handleThumbnailClick(newIndex);
    }
  };

  handleKeyDown = (event) => {
    if (event.key === "ArrowLeft") {
      this.handlePrevClick();
    } else if (event.key === "ArrowRight") {
      this.handleNextClick();
    }
  };

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  render() {
    const { images } = this.props;
    // if (!images || images.length === 0) {
    //   return (
    //     <div>
    //       <strong>არ არის ატვირთული</strong>
    //     </div>
    //   );
    // }

    return (
      <>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {images.map((image, index) => (
            <img
              key={index}
              src={`data:image/png;base64,${image.trumbnail}`}
              alt={`Thumbnail ${index}`}
              style={{
                width: "73px",
                height: "73px",
                margin: "5px",
                border: "1px solid green",
                cursor: "pointer",
              }}
              onClick={() => this.handleThumbnailClick(index)}
            />
          ))}
        </div>

        <Modal
          show={this.state.isFullImageModalVisible}
          onHide={() => this.setState({ isFullImageModalVisible: false })}
          size="xl"
          aria-labelledby="full-image-modal-title"
        >
          <Modal.Body
            style={{
              backgroundColor: "rgb(66, 67, 67)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "93vh",
            }}
          >
            <img
              src={this.state.selectedImage}
              alt="Full Size"
              style={{
                maxHeight: "100%",
                maxWidth: "100%",
                objectFit: "contain",
              }}
            />
            <Button
              variant="secondary"
              style={{
                position: "absolute",
                top: "50%",
                left: "10px",
                transform: "translateY(-50%)",
                zIndex: 1050,
              }}
              onClick={this.handlePrevClick}
              disabled={this.state.currentIndex === 0}
            >
              {"<"}
            </Button>
            <Button
              variant="secondary"
              style={{
                position: "absolute",
                top: "50%",
                right: "10px",
                transform: "translateY(-50%)",
                zIndex: 1050,
              }}
              onClick={this.handleNextClick}
              disabled={
                this.state.currentIndex === this.props.images.length - 1
              }
            >
              {">"}
            </Button>
            <Button
              variant="secondary"
              style={{
                position: "absolute",
                top: "30px",
                right: "10px",
                transform: "translateY(-50%)",
                zIndex: 1050,
              }}
              onClick={() => this.setState({ isFullImageModalVisible: false })}
            >
              {"X"}
            </Button>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default withAuth(Gallery);
