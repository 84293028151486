import axios from "axios";

const url2 = "https://tgservice.ge/apiFront";

export async function signInConfirmAPI(privateNumber, phoneNumber, smsCode) {
  let result = {
    token: null,
    success: false,
    messages: [],
  };

  await fetch(url2 + "/api/Account/confirmSignIn", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Expose-Headers": "*",
    },
    body: JSON.stringify({
      privateNumber,
      phoneNumber,
      smsCode,
    }),
  })
    .then((response) => {
      const token = response.headers.get("accessToken");
      result = { ...result, token, success: response.ok };
      return response.json();
    })
    .then((data) => {
      const messages = data.errors && data.errors.messages.map((text) => text);
      result = { ...result, messages };
    });
  return result;
}

export async function signUpConfirmAPI(privateNumber, phoneNumber, smsCode) {
  let result = {
    token: null,
    success: false,
    messages: [],
  };

  await fetch(url2 + "/api/Account/confirmSignUp", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Expose-Headers": "*",
    },
    body: JSON.stringify({
      privateNumber,
      phoneNumber,
      smsCode,
    }),
  })
    .then((response) => {
      const token = response.headers.get("accessToken");
      result = { ...result, token, success: response.ok };
      return response.json();
    })
    .then((data) => {
      const messages = data.errors && data.errors.messages.map((text) => text);
      result = { ...result, messages };
    });
  return result;
}

export async function passwordRecoveryAPI(
  privateNumber,
  smsCode,
  password,
  confirmedPassword
) {
  let result = {
    token: null,
    success: false,
    messages: [],
  };

  await fetch(url2 + "/api/Account/confirmPasswordRecovery", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Expose-Headers": "*",
    },
    body: JSON.stringify({
      privateNumber,
      smsCode,
      password,
      confirmedPassword,
    }),
  })
    .then((response) => {
      const token = response.headers.get("accessToken");
      result = { ...result, token, success: response.ok };
      return response.json();
    })
    .then((data) => {
      const messages = data.errors && data.errors.messages.map((text) => text);
      result = { ...result, messages };
    });
  return result;
}

export async function phoneNumberChangeAPI(
  privateNumber,
  smsCode,
  password,
  phoneNumber
) {
  let result = {
    token: null,
    success: false,
    messages: [],
  };

  await fetch(url2 + "/api/Account/confirmphoneNumberChange", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Expose-Headers": "*",
    },
    body: JSON.stringify({
      privateNumber,
      smsCode,
      password,
      phoneNumber,
    }),
  })
    .then((response) => {
      const token = response.headers.get("accessToken");
      result = { ...result, token, success: response.ok };
      return response.json();
    })
    .then((data) => {
      const messages = data.errors && data.errors.messages.map((text) => text);
      result = { ...result, messages };
    });
  return result;
}

export async function sendSmsAPI(props) {
  console.log("SENDSMSAPI enter");
  let result = {};
  const {
    privateNumber,
    firstName,
    lastName,
    birthDate,
    email,
    phoneNumber,
    password,
    confirmedPassword,
    actionType,
  } = props;
  let data = {};
  switch (actionType) {
    case 1:
      data = {
        url: `${url2}/api/Account/signIn`,
        params: { privateNumber, phoneNumber, password },
      };
      break;
    case 2:
      data = {
        url: `${url2}/api/Account/signUp/`,
        params: {
          privateNumber,
          firstName,
          lastName,
          birthDate,
          email,
          phoneNumber,
          password,
          confirmedPassword,
        },
      };
      break;
    case 3:
      data = {
        url: `${url2}/api/Account/passwordRecovery/`,
        params: {
          privateNumber,
          firstName,
          lastName,
          birthDate,
        },
      };
      break;
    case 4:
      data = {
        url: `${url2}/api/Account/phoneNumberChange/`,
        params: {
          privateNumber,
          firstName,
          lastName,
          birthDate,
          password,
          phoneNumber,
        },
      };
      break;
    default:
      data = null;
  }

  await fetch(data.url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data.params),
  })
    .then((response) => {
      result = { ...result, success: response.ok, status: response.status };
      return response.json();
    })
    .then((data) => {
      if (!result.success) {
        result = {
          ...result,
          messages:
            data.errors && Object.values(data.errors).map((text) => text),
        };
      } else {
        result = { ...result, messages: data.messages };
      }
    });
  return result;
}

// person general data
export async function getPersonAPI(token) {
  const params = {
    privateNumber: "01001000002",
  };
  let result = {};
  await fetch(url2 + "/api/person/getPersonInfo", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(params),
  })
    .then((res) => res.json())
    .then((data) => (result = data));

  return result;
}

export async function getPersonCRADataAPI(token, privateNumber, lastName) {
  const params = {
    privateNumber: privateNumber,
    lastName: lastName,
  };
  let result = {};
  await fetch(url2 + "/api/person/getPersonCRAData", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(params),
  })
    .then((res) => res.json())
    .then((data) => (result = data));

  return result;
}

export const getStatements = async (token) => {
  try {
    return await axios
      .get(`${url2}/api/Statement`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  } catch (err) {
    throw err;
  }
};
//

export const addStatementAPI = async (token, statement) => {
  console.log("statement", statement);
  var formData = new FormData();
  formData.append("Address", statement.Address);
  formData.append("Description", statement.Description);
  statement.GarbageWasteTypeIds.forEach((id) => {
    formData.append("GarbageWasteTypeIds", id);
  });

  formData.append("Longitude", statement.Longitude);
  formData.append("Latitude", statement.Latitude);
  formData.append("GarbageVolumeCategoryId", statement.GarbageVolumeCategoryId);
  formData.append("RegionId", statement.RegionId);

  try {
    return await axios
      .post(`${url2}/api/Statement`, formData, {
        headers: {
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  } catch (err) {
    throw err;
  }
};

export const updateStatementAPI = async (token, statement) => {
  console.log("Updating statement", statement);
  var formData = new FormData();
  formData.append("StatementId", statement.StatementId);
  formData.append("Address", statement.Address);
  formData.append("Description", statement.Description);
  formData.append("Longitude", statement.Longitude);
  formData.append("Latitude", statement.Latitude);
  statement.GarbageWasteTypeIds.forEach((id) => {
    formData.append("GarbageWasteTypeIds", id);
  });
  formData.append("GarbageVolumeCategoryId", statement.Volume);
  formData.append("RegionId", statement.RegionId);

  try {
    return await axios
      .put(`${url2}/api/Statement`, formData, {
        headers: {
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data, console.log("upd"));
  } catch (err) {
    throw err;
  }
};

export async function uploadFilesAPI(token, filesData) {
  const { StatementId, AttachedFiles } = filesData;
  console.log("filesdataa", filesData);
  let formData = new FormData();
  console.log("statementId", StatementId);
  formData.append("StatementId", StatementId);
  AttachedFiles.map((file, index) =>
    formData.append(`AttachedFiles[${index}]`, file)
  );
  let result = {};
  await fetch(`${url2}/api/Statement/UploadImage`, {
    method: "POST",
    headers: {
      Accept: "*/*",
      // "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.errors) {
        let errors = Object.values(data.errors);
        result = {
          ...result,
          success: false,
          errors: errors.map((text) => text[0]),
        };
      } else {
        result = {
          ...result,
          success: true,
          errors: null,
        };
      }
    });

  return result;
}

export async function uploadWastePlacementFilesAPI(token, filesData) {
  const { StatementId, AttachedFiles } = filesData;
  console.log("filesdataa", filesData);
  let formData = new FormData();
  console.log("statementId", StatementId);
  formData.append("StatementId", StatementId);
  AttachedFiles.map((file, index) =>
    formData.append(`AttachedFiles[${index}]`, file)
  );
  let result = {};
  await fetch(`${url2}/api/Statement/UploadPlacementImage`, {
    method: "POST",
    headers: {
      Accept: "*/*",
      // "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.errors) {
        let errors = Object.values(data.errors);
        result = {
          ...result,
          success: false,
          errors: errors.map((text) => text[0]),
        };
      } else {
        result = {
          ...result,
          success: true,
          errors: null,
        };
      }
    });

  return result;
}

export async function getStatementById(token, id) {
  let result = {};
  await fetch(url2 + `/api/statement/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.json())
    .then((data) => (result = data));

  return result;
}

export async function AcceptStatementById(token, id) {
  let result = {};
  await fetch(url2 + `/api/statement/AcceptStatementByUser/${id}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.json())
    .then((data) => (result = data));

  return result;
}
export async function PaymentFunction(token, statementDetails) {
  const requestBody = JSON.stringify({
    statementId: statementDetails.statementId,
    amount: statementDetails.amount,
  });

  try {
    let response = await fetch(
      `https://tgservice.ge/apiFront/api/Payment/StatementFee`,
      {
        method: "POST",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json-patch+json", //
          Authorization: `Bearer ${token}`,
        },
        body: requestBody,
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    let data = await response.json().catch(() => {
      throw new Error("Invalid JSON response");
    });

    return data;
  } catch (error) {
    console.error("PaymentFunction error: ", error.message);
    throw new Error(`PaymentFunction error: ${error.message}`);
  }
}

export async function RejectStatementById(token, id) {
  let result = {};
  await fetch(url2 + `/api/statement/RejectStatementByUser/${id}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.json())
    .then((data) => (result = data));

  return result;
}

export async function getMessageById(token, statementId) {
  const url = `https://tgservice.ge/apiFront/api/Comment/${statementId}`;

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Failed to fetch messages:", error);
  }
}

export const addMessage = async (token, userId, statementId, messageText) => {
  const url = "https://tgservice.ge/apiFront/api/Comment";
  const messageObj = {
    userId,
    statementId,
    messageText,
  };

  try {
    const response = await axios.post(url, messageObj, {
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.data && response.data.isSuccess) {
      console.log("Message sent successfully:", response.data.messages);
      return response.data;
    } else {
      throw new Error("Failed to send message due to API error.");
    }
  } catch (error) {
    console.error("Error sending message:", error);
    throw error;
  }
};

export async function setSeenStatus(statementId, token) {
  const url = `${url2}/api/Comment/setSeenStatus/${statementId}`;
  let result = {
    success: false,
    messages: [],
  };

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "*/*",
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.ok) {
      const data = await response.json();
      result.success = data.isSuccess;
      result.messages = data.messages || [];
    } else {
      const errorText = await response.text();
      result.messages = [errorText || response.statusText];
    }
  } catch (error) {
    result.messages = [error.message];
  }

  return result;
}

export async function fetchStatementImage(token, id, filename) {
  const baseUrl = "https://tgservice.ge/apiFront/api/Statement";
  const url = `${baseUrl}/${id}/${filename}`;

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const imageBlob = await response.blob();

    const imageUrl = URL.createObjectURL(imageBlob);
    console.log("Image URL:", imageUrl);

    return imageUrl;
  } catch (error) {
    console.error("Fetching image failed:", error);
    throw error;
  }
}
