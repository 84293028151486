import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "../login/Login";

import { homepage } from "../../constants";
import Header from "../header/Header";
import Footer from "../footer/footer";
import PasswordRecovery from "../passwordRecovery/PasswordRecovery";
import PhoneNumberChange from "../phoneNumberChange/PhoneNumberChange";
import NewRequest from "../newRequest/newRequest";
import StatementsList from "../statementsList/statementsList";
import ContactPage from "../contactPage/ContactPage";
import StatementInfo from "../statementinfopage/StatementInfo";
import PaymentPage from "../paymentpage/PaymentPage";
import PaymentFailure from "../../paymentresults/paymentFailure";
import PaymentSuccess from "../../paymentresults/paymentSuccess";
import NotFound from "../notFound/NotFound";
import Register from "../register/Register";
import MainPage from "../main/MainPage";
import Cookies from "js-cookie";
import { loggedOut } from "../../auth/authService";
import withRouter from "../../auth/withRouter";
import "./App.css";
import LandingPage from "../landingPage/LandingPage";

class App extends React.Component {
  render() {
    return (
      <div className="App d-flex flex-column min-vh-100" id="root">
        <div className="flex-grow-1">
          <Routes>
            <Route
              path={`${homepage}/welcome-page`}
              element={<LandingPage />}
            />
            <Route
              path={`${homepage}/login`}
              element={<Login logOut={this.handleLogOut} />}
            />

            <Route
              path={`${homepage}/main-page`}
              element={
                <>
                  <Header />
                  <NewRequest />
                </>
              }
            />
            <Route
              path={`${homepage}/passwordRecovery`}
              element={<PasswordRecovery />}
            />
            <Route path={`${homepage}/registration`} element={<Register />} />
            <Route
              path={`${homepage}/phoneNumberChange`}
              element={<PhoneNumberChange />}
            />
            <Route
              path={`${homepage}/new-request`}
              element={
                <>
                  <Header />
                  <NewRequest />
                </>
              }
            />
            <Route
              path={`${homepage}/my-requests`}
              element={
                <>
                  <Header />
                  <StatementsList />
                </>
              }
            />
            <Route
              path={`${homepage}/contact`}
              element={
                <>
                  <Header />
                  <ContactPage />
                </>
              }
            />
            <Route
              path={`${homepage}/statementInfo/:id`}
              element={
                <>
                  <Header />
                  <StatementInfo />
                </>
              }
            />
            <Route
              path={`${homepage}/paymentPage/:id`}
              element={
                <>
                  <Header />
                  <PaymentPage />
                </>
              }
            />
            <Route
              path={`${homepage}/paymentError`}
              element={
                <>
                  <Header />
                  <PaymentFailure />
                </>
              }
            />
            <Route
              path={`${homepage}/paymentSuccess`}
              element={
                <>
                  <Header />
                  <PaymentSuccess />
                </>
              }
            />
            <Route
              path={`${homepage}/`}
              element={<Navigate to={`${homepage}/my-requests`} />}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(App);
