import React from "react";
import meria from "../../icons/meria.png";
import tbsg from "../../icons/tbsg.png";
import slide1 from "../../icons/1.jpg";
import slide2 from "../../icons/2.jpg";
import slide5 from "../../icons/5.jpg";
import slide6 from "../../icons/6.jpg";
import slide7 from "../../icons/7.jpg";
import slide8 from "../../icons/8.jpg";
import slide9 from "../../icons/9.jpg";
import slide11 from "../../icons/11.jpg";

import { Button, Container, Row, Col, Carousel } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { homepage } from "../../constants";
import withRouter from "../../auth/withRouter";

const LandingPage = () => {
  const navigate = useNavigate();
  const handleRedirect = () => {
    navigate(`${homepage}/login`);
  };

  return (
    <Container
      fluid
      className="d-flex flex-column justify-content-start position-relative min-vh-100"
      style={{ backgroundColor: "rgb(227,238,227)", overflow: "hidden" }}
    >
      {/* Logos and Header Row */}
      <Row
        className="align-items-center justify-content-between"
        style={{
          backgroundColor: "rgb(227,238,227)",
          marginTop: "-35px",
        }}
      >
        {/* Left Logo */}
        <Col
          xs={12}
          md={4}
          className="d-flex justify-content-center justify-content-md-start align-items-center p-2"
        >
          <img
            src={meria}
            alt="first png"
            className="img-fluid"
            style={{ maxWidth: "300px", height: "auto" }} // Reduce max width for smaller devices
          />
        </Col>

        {/* Centered Header */}
        <Col xs={12} md={4} className="text-center p-2">
          <h1
            className="display-4"
            style={{
              fontSize: "clamp(30px, 6vw, 45px)", // Responsive font size using clamp
              fontFamily: "Roboto, sans-serif",
              color: "rgb(84,88,84)",
            }}
          >
            TGService
          </h1>
          <Button variant="outline-success" size="lg" onClick={handleRedirect}>
            საიტზე გადასვლა &rarr;
          </Button>
        </Col>

        {/* Right Logo */}
        <Col
          xs={12}
          md={4}
          className="d-flex justify-content-center justify-content-md-end align-items-center p-2"
        >
          <img
            src={tbsg}
            alt="second png"
            className="img-fluid"
            style={{ maxWidth: "300px", height: "auto" }} // Reduce max width for smaller devices
          />
        </Col>
      </Row>

      {/* Paragraph Section */}
      <Row
        className="text-center d-flex justify-content-center"
        style={{
          fontFamily: "Roboto, sans-serif",
          marginTop: "-20px",
        }}
      >
        <Col xs={11} md={8} lg={6} className="mx-auto">
          <p className="lead" style={{ marginBottom: "8px" }}>
            თუ გჭირდებათ სამშენებლო-სარემონტო ნარჩენების, ძველი ავეჯის,
            ნივთების, ასევე, მწვანე ნარჩენის გატანა, შეგიძლიათ ისარგებლოთ შპს
            „თბილსერვის ჯგუფის“ ახალი მომსახურებით.
          </p>
          <p className="lead" style={{ marginBottom: "0px" }}>
            ამისათვის საჭიროა, დარეგისტრირდეთ ვებგვერდზე, შეავსოთ
            განცხადებისთვის აუცილებელი მონაცემები და შპს „თბილსერვის ჯგუფი“
            უზრუნველყოფს ადგილიდან არასამეწარმეო ნარჩენების გატანას.
          </p>
          <p className="lead" style={{ marginBottom: "20px" }}>
            <strong>
              {" "}
              1 მ<sup>3</sup> - მდე ნარჩენის გატანის საფასური მხოლოდ 9 ლარია!{" "}
            </strong>
          </p>
        </Col>
      </Row>

      {/* Carousel Section */}
      <Row
        className="d-flex justify-content-center"
        style={{ flexGrow: 1, marginTop: "-10px" }}
      >
        <Col xs={12} md={10} lg={8}>
          <Carousel
            controls={false}
            indicators={false}
            interval={3000}
            pause={false}
            className="w-100"
            style={{ height: "40vh" }} // Reduced height
          >
            <Carousel.Item>
              <img
                src={slide1}
                alt="First slide"
                className="d-block w-100"
                style={{ objectFit: "contain", height: "100%" }}
                loading="lazy"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                src={slide2}
                alt="Second slide"
                className="d-block w-100"
                style={{ objectFit: "contain", height: "100%" }}
                loading="lazy"
              />
            </Carousel.Item>

            <Carousel.Item>
              <img
                src={slide5}
                alt="Fifth slide"
                className="d-block w-100"
                style={{ objectFit: "contain", height: "100%" }}
                loading="lazy"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                src={slide6}
                alt="sixth slide"
                className="d-block w-100"
                style={{ objectFit: "contain", height: "100%" }}
                loading="lazy"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                src={slide7}
                alt="seventh slide"
                className="d-block w-100"
                style={{ objectFit: "contain", height: "100%" }}
                loading="lazy"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                src={slide8}
                alt="eighth slide"
                className="d-block w-100"
                style={{ objectFit: "contain", height: "100%" }}
                loading="lazy"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                src={slide9}
                alt="ninth slide"
                className="d-block w-100"
                style={{ objectFit: "contain", height: "100%" }}
                loading="lazy"
              />
            </Carousel.Item>

            <Carousel.Item>
              <img
                src={slide11}
                alt="eleventh slide"
                className="d-block w-100"
                style={{ objectFit: "contain", height: "100%" }}
                loading="lazy"
              />
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(LandingPage);
