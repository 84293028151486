import * as actions from "./ActionTypes";

import {
  getPersonAPI,
  signInConfirmAPI,
  signUpConfirmAPI,
  sendSmsAPI,
  passwordRecoveryAPI,
  phoneNumberChangeAPI,
  getPersonCRADataAPI,
} from "../api/api";

export const authorization =
  (privateNumber, phoneNumber, smsCode, isRegistration) => async (dispatch) => {
    dispatch({ type: actions.AUTHORIZATION_LOAD_BEGIN });
    try {
      let result = {};
      if (isRegistration) {
        result = await signUpConfirmAPI(privateNumber, phoneNumber, smsCode);
      } else {
        result = await signInConfirmAPI(privateNumber, phoneNumber, smsCode);
      }

      dispatch({
        type: actions.AUTHORIZATION_LOAD_SUCCESS,
        payload: { result },
      });
    } catch (err) {
      dispatch({
        type: actions.AUTHORIZATION_LOAD_ERROR,
        payload: { err },
      });
    }
  };

export const passwordRecovery =
  (privateNumber, smsCode, password, confirmedPassword) => async (dispatch) => {
    dispatch({ type: actions.PASSWORD_RECOVERY_LOAD_BEGIN });
    try {
      const result = await passwordRecoveryAPI(
        privateNumber,
        smsCode,
        password,
        confirmedPassword
      );
      dispatch({
        type: actions.PASSWORD_RECOVERY_LOAD_SUCCESS,
        payload: { result },
      });
    } catch (err) {
      dispatch({
        type: actions.PASSWORD_RECOVERY_LOAD_ERROR,
        payload: { err },
      });
    }
  };

export const phoneNumberChange =
  (privateNumber, smsCode, password, phoneNumber) => async (dispatch) => {
    dispatch({ type: actions.PHONE_NUMBER_CHANGE_LOAD_BEGIN });
    try {
      const result = await phoneNumberChangeAPI(
        privateNumber,
        smsCode,
        password,
        phoneNumber
      );
      dispatch({
        type: actions.PHONE_NUMBER_CHANGE_LOAD_SUCCESS,
        payload: { result },
      });
    } catch (err) {
      dispatch({
        type: actions.PHONE_NUMBER_CHANGE_LOAD_ERROR,
        payload: { err },
      });
    }
  };

export const sendSms = (params) => async (dispatch) => {
  const result = await sendSmsAPI(params);
  dispatch({
    type: actions.SEND_SMS,
    payload: { result },
  });
};

//persons
export const getPerson = (token) => async (dispatch) => {
  dispatch({ type: actions.PERSON_LOAD_BEGIN });
  try {
    const result = await getPersonAPI(token);
    dispatch({
      type: actions.PERSON_LOAD_SUCCESS,
      payload: { result },
    });
  } catch (err) {
    dispatch({
      type: actions.PERSON_LOAD_ERROR,
      payload: { err },
    });
  }
};

export const getPersonCRAData =
  (token, privateNumber, lastName) => async (dispatch) => {
    dispatch({ type: actions.PERSON_CRA_DATA_LOAD_BEGIN });
    try {
      const result = await getPersonCRADataAPI(token, privateNumber, lastName);
      dispatch({
        type: actions.PERSON_CRA_DATA_LOAD_SUCCESS,
        payload: { result },
      });
    } catch (err) {
      dispatch({
        type: actions.PERSON_CRA_DATA_LOAD_ERROR,
        payload: { err },
      });
    }
  };
