import React, { useEffect, useRef } from "react";
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import { fromLonLat } from "ol/proj";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { Icon, Style } from "ol/style";
import { FaPlus, FaMinus, FaTimes } from "react-icons/fa";
import "./mapcomponent.css";
import withAuth from "../../auth/withAuth";

const MapComponent = ({ longitude, latitude, onClose }) => {
  const mapRef = useRef();
  const viewRef = useRef();
  const vectorSourceRef = useRef(new VectorSource());
  const markerRef = useRef();

  useEffect(() => {
    if (mapRef.current && longitude !== null && latitude !== null) {
      const initialCoordinates = fromLonLat([longitude, latitude]);

      const view = new View({
        center: initialCoordinates,
        zoom: 16,
      });
      viewRef.current = view;

      const map = new Map({
        target: mapRef.current,
        layers: [
          new TileLayer({
            source: new OSM(),
          }),
        ],
        view: view,
        controls: [],
      });

      const marker = new Feature({
        geometry: new Point(initialCoordinates),
      });

      marker.setStyle(
        new Style({
          image: new Icon({
            scale: 0.08,
            anchor: [0.5, 1],
            src: process.env.PUBLIC_URL + "/location.png",
          }),
        })
      );

      vectorSourceRef.current.addFeature(marker);
      markerRef.current = marker;

      const vectorLayer = new VectorLayer({
        source: vectorSourceRef.current,
      });

      map.addLayer(vectorLayer);

      return () => {
        map.setTarget(null);
      };
    }
  }, [longitude, latitude]);

  useEffect(() => {
    if (longitude !== null && latitude !== null && markerRef.current) {
      const newCoordinates = fromLonLat([longitude, latitude]);
      markerRef.current.setGeometry(new Point(newCoordinates));
      if (viewRef.current) {
        viewRef.current.setCenter(newCoordinates);
      }
    }
  }, [longitude, latitude]);

  const zoomIn = (event) => {
    event.preventDefault();
    const view = viewRef.current;
    view.animate({
      zoom: view.getZoom() + 1,
      duration: 500,
    });
  };

  const zoomOut = (event) => {
    event.preventDefault();
    const view = viewRef.current;
    view.animate({
      zoom: view.getZoom() - 1,
      duration: 500,
    });
  };

  return (
    <div className="map-container" style={{ height: "700px", width: "100%" }}>
      <div ref={mapRef} className="map"></div>
      <div className="map-buttons">
        <button className="zoom-button" onClick={zoomIn}>
          <FaPlus />
        </button>
        <button className="zoom-button" onClick={zoomOut}>
          <FaMinus />
        </button>
      </div>
      <button className="close-button" onClick={onClose}>
        <FaTimes />
      </button>
    </div>
  );
};

export default withAuth(MapComponent);
