import React from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { authorize } from "../../auth/authService";
import { Link } from "react-router-dom";
import withRouter from "../../auth/withRouter";
import { Form, InputGroup } from "react-bootstrap";
import { homepage } from "../../constants";
import SmsControl from "../controls/sms/SmsControl";
import { authorization } from "../../actions/Actions";
import "./Login.css";

class Login extends React.Component {
  state = {
    privateNumber: "",
    password: "",
    phoneNumber: "",
    smsCode: "",
    isLoginButtonEnabled: false,
    actionType: 1,
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value }, this.validateForm);
  };

  validateForm = () => {
    const { privateNumber, phoneNumber, smsCode, password } = this.state;
    return (
      privateNumber.length === 11 &&
      phoneNumber.length === 9 &&
      smsCode.length === 4 &&
      password.length > 0
    );
  };

  handleLogin = async (event) => {
    event.preventDefault();
    try {
      const form = event.currentTarget;
      if (form.checkValidity() && this.validateForm()) {
        const { privateNumber, phoneNumber, smsCode } = this.state;
        const isRegistration = false;
        await this.props.authorization(
          privateNumber,
          phoneNumber,
          smsCode,
          isRegistration
        );
        const { token, success, messages } = this.props.login;
        if (success) {
          authorize(token);
          console.log("Login successful, navigating to new request");
          this.props.router.navigate(`${homepage}/new-request`);
        } else {
          Swal.fire(messages.join(", "));
        }
      }
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  render() {
    const { privateNumber, password, phoneNumber, smsCode, actionType } =
      this.state;
    return (
      <div className="auth-block">
        <div className="container-fluid">
          <div className="row">
            <div className="col col-12 login-half">
              <Form
                noValidate
                validated={this.validateForm()}
                onSubmit={this.handleLogin}
              >
                <Form.Label
                  className="form-label"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    marginBottom: "-45px",
                  }}
                >
                  <img
                    className="loginPhoto"
                    src={require("../../static/images/logotop.png")}
                    style={{ width: 200, height: 100, marginTop: "-3rem" }}
                    alt="no_photo"
                  />
                  <div
                    className="form-header"
                    style={{ marginTop: -20, marginLeft: "-3.5px" }}
                  >
                    ავტორიზაცია
                  </div>
                </Form.Label>
                <Form.Group controlId="validationForm1">
                  <Form.Label className="font-weight-bold"></Form.Label>
                  <label
                    className="labelsForLogin"
                    htmlFor="ID Number"
                    autoComplete="off"
                  >
                    პირადი ნომერი
                  </label>
                  <Form.Control
                    className="inputValues"
                    autoComplete="off"
                    required
                    type="number"
                    name="privateNumber"
                    placeholder=""
                    value={privateNumber}
                    onChange={this.handleChange}
                    isInvalid={
                      privateNumber.length !== 11 && privateNumber.length > 0
                    }
                    isValid={privateNumber.length === 11}
                  />
                  <Form.Control.Feedback type="invalid">
                    პირადი ნომერი არ უნდა აღემატებოდეს 11 სიმბოლოს!
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="validationForm4">
                  <Form.Label className="font-weight-bold"></Form.Label>
                  <label className="labelsForLogin" htmlFor="Password">
                    პაროლი
                  </label>
                  <Form.Control
                    className="inputValues"
                    autoComplete="off"
                    required
                    type="password"
                    name="password"
                    placeholder=""
                    value={password}
                    onChange={this.handleChange}
                    isValid={password.length > 0}
                  />
                  <Form.Control.Feedback type="invalid">
                    გთხოვთ შეიყვანოთ პაროლი!
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="validationForm2">
                  <Form.Label className="font-weight-bold"></Form.Label>
                  <label className="labelsForLogin" htmlFor="Mobile Number">
                    მობილურის ნომერი
                  </label>
                  <InputGroup>
                    <Form.Control
                      className="inputValues"
                      autoComplete="off"
                      required
                      type="number"
                      name="phoneNumber"
                      placeholder=""
                      value={this.state.phoneNumber}
                      onChange={this.handleChange}
                      isInvalid={
                        phoneNumber.length !== 9 && phoneNumber.length > 0
                      }
                      isValid={phoneNumber.length === 9}
                    />
                    <div className="input-group-append">
                      <SmsControl
                        {...{
                          privateNumber,
                          phoneNumber,
                          password,
                          actionType,
                        }}
                        disabled={
                          !(
                            privateNumber.trim().length === 11 &&
                            phoneNumber.trim().length === 9
                          )
                        }
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      გთხოვთ, შეიყვანოთ სწორი მობილურის ნომერი!
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group controlId="validationForm3">
                  <Form.Label className="font-weight-bold"></Form.Label>
                  <label className="labelsForLogin" htmlFor="SMS Code">
                    სმს კოდი
                  </label>
                  <Form.Control
                    className="inputValues"
                    autoComplete="off"
                    required
                    type="number"
                    name="smsCode"
                    placeholder=""
                    value={smsCode}
                    onChange={this.handleChange}
                    isInvalid={smsCode.length !== 4 && smsCode.length > 0}
                    isValid={smsCode.length === 4}
                    style={{ marginBottom: "-8px" }}
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    style={{ marginTop: "15px" }}
                  >
                    სმს კოდი შეავსეთ კორექტულად!
                  </Form.Control.Feedback>
                </Form.Group>
                <br />
                <Link
                  className="loginButtons"
                  to={`${homepage}/passwordRecovery`}
                >
                  პაროლის აღდგენა
                </Link>
                <br />
                <Link
                  className="loginButtons"
                  to={`${homepage}/phoneNumberChange`}
                >
                  ნომრის განახლება
                </Link>
                <br />
                <br />
                <div className="form-group">
                  <button
                    className="loginbtn btn btn-block"
                    disabled={
                      !this.validateForm() || this.state.isLoginButtonEnabled
                    }
                    style={{ marginTop: "-10px", marginBottom: "-8px" }}
                  >
                    შესვლა
                  </button>
                </div>
                <Link className="loginButtons" to={`${homepage}/registration`}>
                  გაიარეთ რეგისტრაცია
                </Link>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ loginReducer: { login } }) {
  return { login };
}

export default withRouter(connect(mapStateToProps, { authorization })(Login));
