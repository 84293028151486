import {
  PENSION_STATEMENTS_LOAD_BEGIN,
  PENSION_STATEMENTS_LOAD_SUCCESS,
  PENSION_STATEMENTS_LOAD_ERROR
} from "../actions/ActionTypes";

const initialState = {
  statements: null,
  success: true,
  loader: false,
  error: null,
};

const pensionStatementsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case PENSION_STATEMENTS_LOAD_BEGIN:
      return { ...state, loader: true, error: null };
    case PENSION_STATEMENTS_LOAD_SUCCESS:
      console.log("pensionStatementsReducer",payload.result)
      return { ...state, statements: payload.result, loader: false, error: null };
    case PENSION_STATEMENTS_LOAD_ERROR:
      return { ...state, error: payload, loader: false };
    default:
      return state;
  }
};

export default pensionStatementsReducer;
