import {
  PHONE_NUMBER_CHANGE_LOAD_BEGIN,
  PHONE_NUMBER_CHANGE_LOAD_SUCCESS,
  PHONE_NUMBER_CHANGE_LOAD_ERROR,
} from '../actions/ActionTypes'

const initialState = {
  login: null,
  loader: false,
  error: null,
}

const phoneNumberChangeReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case PHONE_NUMBER_CHANGE_LOAD_BEGIN:
      return { loader: true, error: null }
    case PHONE_NUMBER_CHANGE_LOAD_SUCCESS:
      return {
        login: payload.result,
        loader: false,
        error: null,
      }
    case PHONE_NUMBER_CHANGE_LOAD_ERROR:
      console.log("err",payload)
      return { error: payload, loader: false }
    default:
      return state
  }
}

export default phoneNumberChangeReducer
